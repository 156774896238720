import { NavLink } from "react-router-dom";
import { IoBarChartSharp } from "react-icons/io5";
import { MdQueryStats } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
const links = [
  {
    id: 1,
    text: "stats",
    path: "/",
    Icon: IoBarChartSharp,
  },
  {
    id: 2,
    text: "My predictions",
    path: "my-predictions",
    Icon: MdQueryStats,
  },
  {
    id: 3,
    text: "Prediction Shop",
    path: "prediction-shop",
    Icon: <FaShoppingCart />,
  },
  {
    id: 4,
    text: "profile",
    path: "profile",
    Icon: <ImProfile />,
  },
];

const NavLinks = ({ toggleSidebar }) => {
  return (
    <div className="nav-links">
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
        onClick={toggleSidebar}
        end
      >
        <IoBarChartSharp
          className="icon"
          style={({ isActive }) => ({
            fontSize: isActive ? "32px" : "24px",
          })}
        />

        {"stats"}
      </NavLink>
      <NavLink
        to="my-predictions"
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
        onClick={toggleSidebar}
        end
      >
        <MdQueryStats
          className="icon"
          style={({ isActive }) => ({
            fontSize: isActive ? "32px" : "24px",
          })}
        />

        {"My predictions"}
      </NavLink>
      <NavLink
        to="prediction-shop"
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
        onClick={toggleSidebar}
        end
      >
        <FaShoppingCart
          className="icon"
          style={({ isActive }) => ({
            fontSize: isActive ? "32px" : "24px",
          })}
        />

        {"Prediction Shop"}
      </NavLink>
      <NavLink
        to="profile"
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
        onClick={toggleSidebar}
        end
      >
        <ImProfile
          className="icon"
          style={({ isActive }) => ({
            fontSize: isActive ? "32px" : "24px",
          })}
        />

        {"Profile"}
      </NavLink>
    </div>
  );
};

export default NavLinks;
