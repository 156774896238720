import "./SportsNavbar.css";
import { ReactComponent as SoccerIcon } from "../../assets/icons/soccer.svg";
import { ReactComponent as AFIcon } from "../../assets/icons/american_football.svg";
import { ReactComponent as BasketballIcon } from "../../assets/icons/basketball.svg";
import { ReactComponent as HandballIcon } from "../../assets/icons/handball.svg";
import { ReactComponent as VolleyballIcon } from "../../assets/icons/volleyball.svg";
import { ReactComponent as TennisIcon } from "../../assets/icons/tennis.svg";
import { ReactComponent as HockeyIcon } from "../../assets/icons/hockey.svg";
import { ReactComponent as BaseballIcon } from "../../assets/icons/baseball.svg";
const SportsNavbar = ({ selectedSport, setSelectedSport }) => {
  //const [selectedSport, setSelectedSport] = useState("soccer");
  //const [selectedOddsButton, setSelectedOddsButton] = useState('');

  const handleSportClick = (event) => {
    event.preventDefault();
    const selectedSport = event.currentTarget.id;

    setSelectedSport(selectedSport);

    // console.log(event.currentTarget.id + `was selected`);
  };

  return (
    <>
      <nav className="SportsNavbar">
        <h1 className="sports__h1">Predictions</h1>
        <ul className="sportsNavbarItems">
          <li onClick={handleSportClick} id="Soccer">
            <button
              type="button"
              className={selectedSport === "Soccer" ? "active" : ""}
            >
              <SoccerIcon className="sportsIcon" />
              Soccer
            </button>
          </li>
          <li onClick={handleSportClick} id="USFootball">
            <button
              type="button"
              className={selectedSport === "USFootball" ? "active" : ""}
            >
              <AFIcon className="sportsIcon" />
              Football
            </button>
          </li>
          <li onClick={handleSportClick} id="Basketball">
            <button
              type="button"
              className={selectedSport === "Basketball" ? "active" : ""}
            >
              <BasketballIcon className="sportsIcon" />
              Basketball
            </button>
          </li>
          <li onClick={handleSportClick} id="Handball">
            <button
              type="button"
              className={selectedSport === "Handball" ? "active" : ""}
            >
              <HandballIcon className="sportsIcon" />
              Handball
            </button>
          </li>
          <li onClick={handleSportClick} id="Volleyball">
            <button
              type="button"
              className={selectedSport === "Volleyball" ? "active" : ""}
            >
              <VolleyballIcon className="sportsIcon" />
              Volleyball
            </button>
          </li>
          <li onClick={handleSportClick} id="Tennis">
            <button
              type="button"
              className={selectedSport === "Tennis" ? "active" : ""}
            >
              <TennisIcon className="sportsIcon" />
              Tennis
            </button>
          </li>

          <li onClick={handleSportClick} id="Hockey">
            <button
              type="button"
              className={selectedSport === "Hockey" ? "active" : ""}
            >
              <HockeyIcon className="sportsIcon" />
              Hockey
            </button>
          </li>
          <li onClick={handleSportClick} id="Baseball">
            <button
              type="button"
              className={selectedSport === "Baseball" ? "active" : ""}
            >
              <BaseballIcon className="sportsIcon" />
              Baseball
            </button>
          </li>
        </ul>
      </nav>

      <div></div>
    </>
  );
};

export default SportsNavbar;
