import React, { useState } from "react";

import BarChart from "./BarChart";
import AreaChart from "./AreaChart";
import { useAppContext } from "../../context/appContext";
import "./ChartsContainer.css";
const ChartsContainer = () => {
  const [barChart, setBarChart] = useState(true);
  const { monthlyPredictions: data } = useAppContext();
  return (
    <section className="charts-container">
      <h4>Monthly Predictions</h4>
      <button
        type="button"
        className="chart-btn"
        onClick={() => setBarChart(!barChart)}
      >
        {barChart ? "Show Area Chart" : "Show Bar Chart"}
      </button>
      {barChart ? <BarChart data={data} /> : <AreaChart data={data} />}
    </section>
  );
};

export default ChartsContainer;
