import React, { useState } from 'react'
import './ReadMoreLess.css'
const  ReadMoreLess= ({children,maxParagraph})=> {
    const [isTruncated, setIsTruncated] =useState(true)
const  toggleIsTruncated =()=>{setIsTruncated(!isTruncated)}  
const processChild = (child) => {
    if (typeof child === 'string') {
      
    return child;
        }

    if (child.props.children) {
     const processedChildren = React.Children.map(child.props.children, processChild);
      return React.cloneElement(child, {}, processedChildren);
    }

    return child;
  };

  const processedChildren = React.Children.map(children, processChild);

  return  (

    <div className='read-more-less' >
 {isTruncated ? processedChildren.slice(0, maxParagraph) : processedChildren}


      {isTruncated && <span>...</span>}
      <button className='read-button' onClick={toggleIsTruncated}>{isTruncated? "Read More":"Show Less"}</button>
      </div>
  )
}

export default ReadMoreLess;
