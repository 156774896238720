import React from "react";
import "./FormInput.css";
import { useState } from "react";
const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;
  const handleFocus = (e) => {
    setFocused(true);
  };
  const handleAge = (e) => {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    const birthday = new Date(e.target.value);
    // const birthday = e.target.value;
    if (birthday > eighteenYearsAgo) {
      e.target.setCustomValidity("You must be 18 years or older to sign up");
    } else {
      e.target.setCustomValidity("");
    }

    setFocused(true);
  };
  return (
    <div className="form-input">
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        {...inputProps}
        onChange={onChange}
        //handleChange={handleChange} // newly added
        onBlur={inputProps.name === "birthday" ? handleAge : handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </div>
  );
};

export default FormInput;
