import "./SmallSidebar.css";
import { FaTimes } from "react-icons/fa";
import { useAppContext } from "../../context/appContext";

import Logo from "../Logo/Logo";
import NavLinks from "../NavLinks/NavLinks";
const SmallSidebar = () => {
  const { showSidebar, toggleSidebar } = useAppContext();
  return (
    <aside className="small-sidebar">
      <div
        className={
          showSidebar ? "sidebar-container show-sidebar" : "sidebar-container"
        }
      >
        <div className="content">
          <button
            type="button"
            title="close menu"
            className="close-btn"
            onClick={toggleSidebar}
          >
            <FaTimes />
          </button>
          <Logo />
          <NavLinks toggleSidebar={toggleSidebar} />
        </div>
      </div>
    </aside>
  );
};

export default SmallSidebar;
