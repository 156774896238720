export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SIGNUP_USER_BEGIN = "SIGNUP_USER_BEGIN";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const CREATE_PREDICTION_BEGIN = "CREATE_PREDICTION_BEGIN";
export const CREATE_PREDICTION_SUCCESS = "CREATE_PREDICTION_SUCCESS";
export const CREATE_PREDICTION_ERROR = "CREATE_PREDICTION_ERROR";

export const CHANGE_ODDS_FORMAT = "CHANGE_ODDS_FORMAT";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

export const GET_TOTAL_ODD = "GET_TOTAL_ODD";

export const GET_PREDICTIONS_BEGIN = "GET_PREDICTIONS_BEGIN";
export const GET_PREDICTIONS_SUCCESS = "GET_PREDICTIONS_SUCCESS";

export const SET_PREDICTION_SUCCSSFUL_BEGIN = "SET_PREDICTION_SUCCESSFUL_BEGIN";
export const SET_PREDICTION_SUCCESSFUL_SUCCESS =
  "SET_PREDICTION_SUCCESSFUL_SUCCESS";
export const SET_PREDICTION_SUCCESSFUL_ERROR =
  "SET_PREDICTION_SUCCESSFUL_ERROR";

export const REFUND_PREDICTION_BEGIN = "REFUND_PREDICTION_BEGIN";
export const REFUND_PREDICTION_SUCCESS = "REFUND_PREDICTION_SUCCESSS";
export const REFUND_PREDICTION_ERROR = "REFUND_PREDICTION_ERRORR";
export const SHOW_STATS_BEGIN = "SHOW_STATS_BEGIN";
export const SHOW_STATS_SUCCESS = "SHOW_STATS_SUCCESS";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CHANGE_PAGE = "CHANGE_PAGE";

export const DELETE_ACCOUNT_BEGIN = "DELETE_ACCOUNT_BEGIN";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_SUCCESS";

export const SET_REDIRECTED_TRUE = "SET_REDIRECTED_TRUE";
export const SET_REDIRECTED_FALSE = "SET_REDIRECTED_FALSE";
