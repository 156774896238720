import "./SearchContainer.css";
import FormRowSelect from "../FormRowSelect/FormRowSelect";
import FormRow from "../FormRow/FormRow";
import { useAppContext } from "../../context/appContext";
import { useState, useMemo } from "react";
const SearchContainer = () => {
  const [localSearch, setLocalSearch] = useState("");
  const {
    isLoading,
    searchStatus,
    sort,
    sortOptions,
    handleChange,
    clearFilters,
    statusOptions,
  } = useAppContext();
  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLocalSearch("");
    clearFilters();
  };
  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        handleChange({ name: e.target.name, value: e.target.value });
      }, 1000);
    };
  };
  const optimizedDebounce = useMemo(() => debounce(), []);
  return (
    <section className="search">
      <form className="form">
        <h4>Search form</h4>
        <div className="form-center">
          {/* search position */}

          <FormRow
            title="search"
            labelText="search teams"
            type="text"
            name="search"
            id="search"
            value={localSearch}
            handleChange={optimizedDebounce}
          />
          {/* search by status */}
          <FormRowSelect
            title="status"
            labelText="status"
            name="searchStatus"
            id="searchStatus"
            value={searchStatus}
            handleChange={handleSearch}
            list={["all", ...statusOptions]}
          />
          {/* sort */}
          <FormRowSelect
            title="sort"
            name="sort"
            id="sort"
            value={sort}
            handleChange={handleSearch}
            list={sortOptions}
          />
          <button
            type="button"
            className="btn btn-block btn-danger"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            clear filters
          </button>
        </div>
      </form>
    </section>
  );
};

export default SearchContainer;
