import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({ data }) => {
  return (
    <div style={{ backgroundColor: "#fff", padding: "20px", margin: "10px" }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 50 }}
          fill="#fff"
          backgroundColor="#fff"
        >
          <CartesianGrid
            backgroundColor="#fff"
            stroke="#660099"
            strokeDasharray="3 3"
          />
          <XAxis dataKey="date" stroke="#660099" />
          <YAxis allowDecimals={false} stroke="#660099" />
          <Tooltip dataKey="successful" fill="#0f5132" />

          <Bar dataKey="successful" stackId="a" fill="#0f5132" barSize={75} />
          <Bar dataKey="refunded" stackId="a" fill="#ff0080" barSize={75} />
          <Bar dataKey="pending" stackId="a" fill="#FFD700" barSize={75} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
