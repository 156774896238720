import { useEffect, useState } from "react";
import FormInput from "../components/FormInput/FormInput";
import "./LogIn.css";
import { NavLink, useNavigate } from "react-router-dom";

import { useAppContext } from "../context/appContext";
import Alert from "../components/Alert/Alert";
import { Loading } from "../components";
import LogInFigure from "../assets/images/others/log-in.png";
import Logo from "../assets/icons/WinperksLogo.svg";
const LogIn = () => {
  const navigate = useNavigate();
  const { isLoading, showAlert, displayAlert, loginUser, user } =
    useAppContext();

  //global state and useNavigate
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Please write your valid email address!",
      label: "Email",
      autoComplete: "email",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage: "Please write your password! ",
      label: "Password",
      //pattern: "^(?=.*\\p{L})(?=.*\\p{N}).{8,20}$",
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = values;
    if (!email || !password) {
      displayAlert();
      return;
    }
    const currentUser = { email, password };
    loginUser(currentUser);
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [user]);
  return (
    <main className="log-in-container">
      <div className="logIn-logo">
        <img src={Logo} alt="winperks" />
      </div>
      <div>
        <div className="log-in">
          <div className="form-container-logIn">
            <form onSubmit={handleSubmit}>
              <h1>Log In</h1>
              {showAlert && <Alert />}
              {isLoading ? (
                <Loading center={true} />
              ) : (
                <>
                  {inputs.map((input) => (
                    <FormInput
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  ))}
                </>
              )}
              <button type="submit" disabled={isLoading}>
                Log In
              </button>
              <p>
                You don't have an account?
                <NavLink className="sign-up__link" to="/signup">
                  Sign Up
                </NavLink>
              </p>
              <br />
              <p>
                Forgot password?
                <NavLink className="sign-up__link" to="/forgot-password">
                  Reset Password
                </NavLink>
              </p>
            </form>
          </div>
          <div className="vl-li"></div>
          <div className="image-container-logIn">
            <img src={LogInFigure} alt="shiny clover" className="log-in-img" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default LogIn;
