import "./DNavbar.css";
import { useEffect, useState } from "react";
import { FaAlignLeft, FaUserCircle, FaCaretDown } from "react-icons/fa";
import { useAppContext } from "../../context/appContext";
import Logo from "../Logo/Logo";
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";

const DNavbar = () => {
  const {
    toggleSidebar,
    showSidebar,
    logoutUser,
    user,
    oddsFormat,
    changeOddsFormat,
    currency,
    changeCurrency,
  } = useAppContext();
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [showLogout, setShowLogout] = useState(false);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");
  const gmtOffset = time.getTimezoneOffset() >= 0 ? "-" : "+";
  const absOffset = Math.abs(time.getTimezoneOffset());
  const gmtDisplay = `UTC${gmtOffset}${Math.floor(absOffset / 60)
    .toString()
    .padStart(2, "0")}:${(absOffset % 60).toString().padStart(2, "0")}`;

  return (
    <nav className="dnavbar">
      <div className="nav-center">
        <button
          type="button"
          title="toggle sidebar"
          className="toggle-btn"
          onClick={toggleSidebar}
        >
          <FaAlignLeft />
        </button>
        <div className="clock-format">
          <div title="time" className="clock">
            {hours}:{minutes}:{seconds} {gmtDisplay}
          </div>
          <div className="odds-format__select">
            <select
              title="odds format"
              name="odds format"
              value={oddsFormat}
              onChange={(e) => changeOddsFormat(e.target.value)}
            >
              <option value="decimal" className="option">
                Decimal Odds
              </option>
              <option value="american" className="option">
                American Odds
              </option>
            </select>
          </div>
          <div className="currency__select">
            <select
              title="currency"
              name="currency"
              value={currency}
              onChange={(e) => {
                changeCurrency(e.target.value);
                dispatch({
                  type: "resetOptions",
                  value: {
                    ...options,
                    currency: e.target.value,
                  },
                });
              }}
            >
              <option value="USD" className="option">
                USD - $
              </option>
              <option value="EUR" className="option">
                EUR - €
              </option>
            </select>
          </div>
        </div>
        {showSidebar && (
          <div>
            <Logo />
          </div>
        )}
        <h3 className="logo-text">Dashboard</h3>

        <div className="btn-container">
          <button
            type="button"
            className={!showLogout ? "btn" : "btn clicked"}
            onClick={() => setShowLogout(!showLogout)}
          >
            <FaUserCircle />
            {user?.firstName}
            <FaCaretDown />
          </button>
          <div className={showLogout ? "dropdown show-dropdown" : "dropdown"}>
            <button type="button" onClick={logoutUser} className="dropdown-btn">
              logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DNavbar;
