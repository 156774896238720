import { useAppContext } from "../../context/appContext";
import StatItem from "../StatItem/StatItem";
import { FaSuitcaseRolling, FaCalendarCheck, FaBug } from "react-icons/fa";
import "./StatsContainer.css";
const StatsContainer = () => {
  const { stats } = useAppContext();

  const defaultStats = [
    {
      title: "pending predictions",
      count: stats.pending || 0,
      icon: <FaSuitcaseRolling />,
      color: "#FFD700",
      bcg: "#fcefc7",
    },
    {
      title: "successful predictions",
      count: stats.successful || 0,
      icon: <FaCalendarCheck />,
      color: "#0f5132",
      bcg: "#a3e5c6",
    },
    {
      title: "refunded predictions",
      count: stats.refunded || 0,
      icon: <FaBug />,
      color: "#ff0080",
      bcg: "#ffeeee",
    },
  ];

  return (
    <section className="stats-container">
      {defaultStats.map((item, index) => {
        return <StatItem key={index} {...item} />;
      })}
    </section>
  );
};

export default StatsContainer;
