import "./StatItem.css";
const StatsItem = ({ count, title, icon, color, bcg }) => {
  const style = {
    "--color": color,
    "--background-color": bcg,
  };
  return (
    <article style={style} className="stat-item">
      <header>
        <span className="count">{count}</span>
        <span className="icon">{icon}</span>
      </header>
      <h5 className="title">{title}</h5>
    </article>
  );
};

export default StatsItem;
