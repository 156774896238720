import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SIGNUP_USER_BEGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CREATE_PREDICTION_BEGIN,
  CREATE_PREDICTION_SUCCESS,
  CREATE_PREDICTION_ERROR,
  CHANGE_ODDS_FORMAT,
  GET_PREDICTIONS_BEGIN,
  GET_PREDICTIONS_SUCCESS,
  GET_TOTAL_ODD,
  SET_PREDICTION_SUCCSSFUL_BEGIN,
  SET_PREDICTION_SUCCESSFUL_SUCCESS,
  SET_PREDICTION_SUCCESSFUL_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  HANDLE_CHANGE,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  REFUND_PREDICTION_BEGIN,
  REFUND_PREDICTION_SUCCESS,
  REFUND_PREDICTION_ERROR,
  CHANGE_CURRENCY,
  DELETE_ACCOUNT_BEGIN,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  SET_REDIRECTED_TRUE,
  SET_REDIRECTED_FALSE,
} from "./actions";
import { initialState } from "./appContext";
const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: "Please provide all values!",
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }
  if (action.type === SIGNUP_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SIGNUP_USER_SUCCESS) {
    return {
      ...state,
      /*   user: action.payload.user,
      token: action.payload.token,
      userLocation: action.payload.location,
      jobLocation: action.payload.location, */
      userCreated: true,
      showAlert: true,
      alertType: "success",
      // alertText: "Email sent to your account, please verify",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }
  if (action.type === SIGNUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === LOGIN_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      token: action.payload.token,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      showAlert: true,
      alertType: "success",
      alertText: "Login Successful! Redirecting...",
    };
  }
  if (action.type === LOGIN_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_SIDEBAR) {
    return { ...state, showSidebar: !state.showSidebar };
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
      userLocation: "",
      jobLocation: "",
    };
  }
  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userLocation: action.payload.location,
      jobLocation: action.payload.location,
      showAlert: true,
      alertType: "success",
      alertText: "User Profile Updated!",
    };
  }
  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_PREDICTION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_PREDICTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Prediction Created! Redirecting...",
    };
  }
  if (action.type === CREATE_PREDICTION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CHANGE_ODDS_FORMAT) {
    return {
      ...state,
      oddsFormat: action.payload,
    };
  }
  if (action.type === CHANGE_CURRENCY) {
    return {
      ...state,
      currency: action.payload,
    };
  }

  if (action.type === GET_TOTAL_ODD) {
    return {
      ...state,
      totalOdd: action.payload.totalOdd,
    };
  }
  if (action.type === GET_PREDICTIONS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_PREDICTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      predictions: action.payload.predictions,
      totalPredictions: action.payload.totalPredictions,
      numOfPages: action.payload.numOfPages,
    };
  }
  if (action.type === SET_PREDICTION_SUCCSSFUL_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_PREDICTION_SUCCESSFUL_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Prediction successful!",
    };
  }
  if (action.type === SET_PREDICTION_SUCCESSFUL_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === REFUND_PREDICTION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === REFUND_PREDICTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Prediction refunded!",
    };
  }
  if (action.type === REFUND_PREDICTION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SHOW_STATS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === SHOW_STATS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stats: action.payload.stats,
      monthlyPredictions: action.payload.monthlyPredictions,
    };
  }
  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      page: 1,
      [action.payload.name]: action.payload.value,
    };
  }
  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,
      search: "",
      searchStatus: "all",
      sort: "latest",
    };
  }
  if (action.type === CHANGE_PAGE) {
    return { ...state, page: action.payload.page };
  }
  if (action.type === DELETE_ACCOUNT_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === DELETE_ACCOUNT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Account Deleted Successfully!",
    };
  }
  if (action.type === DELETE_ACCOUNT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SET_REDIRECTED_TRUE) {
    return {
      ...state,
      redirected: true,
    };
  }
  if (action.type === SET_REDIRECTED_FALSE) {
    return {
      ...state,
      redirected: false,
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
