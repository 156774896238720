export function decimalToAmerican(decimalOdds) {
  let americanOdds = 0;

  if (decimalOdds < 2.0) {
    americanOdds = -100 / (decimalOdds - 1);
  } else {
    americanOdds = (decimalOdds - 1) * 100;
  }

  return (americanOdds >= 0 ? "+" : "") + americanOdds;
}

export const SVGWithOddsSquare = ({ odds, oddsFormat }) => {
  let oddsText = "";

  if (oddsFormat === "decimal") {
    /* oddsText = odds.toFixed(2); */
    oddsText = odds;
  } else if (oddsFormat === "american") {
    oddsText = `${decimalToAmerican(odds)}`;
  }

  return (
    <svg
      viewBox="0 0 100 100"
      width="100"
      height="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="10" y="10" width="80" height="80" fill="#660099" />
      <text
        x="50"
        y="50"
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{ fontSize: "32px", fontFamily: "Roboto" }}
      >
        {oddsText}
      </text>
    </svg>
  );
};

export const SVGWithOddsCircle = ({ odds, oddsFormat }) => {
  let oddsText = "";

  if (oddsFormat === "decimal") {
    oddsText = odds;
  } else if (oddsFormat === "american") {
    oddsText = `${decimalToAmerican(odds)}`;
  }

  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 53 53"
      version="1.1"
      id="svg732"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs730" />
      <g id="layer1" transform="translate(-88.767496,-94.852918)">
        <circle
          style={{
            fill: "#ff4500",
            fillOpacity: 1,
            stroke: "#ff4500",
            strokeWidth: 0.26197,
            strokeOpacity: 0.8,
          }}
          id="path1659"
          cx="115.2675"
          cy="121.35291"
          r="26.369015"
        />
        <text
          x="115.2675"
          y="121.35291"
          textAnchor="middle"
          dominantBaseline="central"
          fill="white"
          style={{ fontSize: "21px", fontFamily: "Roboto" }}
        >
          {oddsText}
        </text>
      </g>
    </svg>
  );
};

export const SVGWithOddsTriangle = ({ odds, oddsFormat }) => {
  let oddsText = "";

  if (oddsFormat === "decimal") {
    oddsText = odds;
  } else if (oddsFormat === "american") {
    oddsText = `${decimalToAmerican(odds)}`;
  }

  return (
    <svg
      width="107.20712"
      height="100"
      viewBox="0 0 15.5 16"
      version="1.1"
      id="svg731"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs730" />
      <path
        style={{
          fill: "#ff00ff",
          fillOpacity: 1,
          stroke: "#ff00ff",
          strokeWidth: 0.26197,
          strokeOpacity: 0.8,
        }}
        id="path341"
        d="m 28.027144,19.979491 c -1.266125,2.192993 -13.62946,2.192993 -14.895585,0 -1.266125,-2.192992 4.915543,-12.8999546 7.447792,-12.8999546 2.53225,0 8.713917,10.7069626 7.447793,12.8999546 z"
        transform="translate(-12.82945,-6.9470369)"
      />
      <text
        x="7.5"
        y="10"
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{ fontSize: "6px", fontFamily: "Roboto" }}
      >
        {oddsText}
      </text>
    </svg>
  );
};

export const SVGWithOddsRect = ({ odds, oddsFormat }) => {
  let oddsText = "";

  if (oddsFormat === "decimal") {
    /* oddsText = odds.toFixed(2); */
    oddsText = odds;
  } else if (oddsFormat === "american") {
    oddsText = `${decimalToAmerican(odds)}`;
  }

  return (
    <svg
      width="120"
      height="90"
      viewBox="0 0 48.154583 34.92541"
      version="1.1"
      id="svg735"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs730" />
      <rect
        style={{
          fill: "#ff0080",
          fillOpacity: 1,
          stroke: "#ff0080",
          strokeWidth: 0.26197,
          strokeOpacity: 0.8,
        }}
        id="rect2497"
        width="47.889584"
        height="34.660412"
        x="0.1324995"
        y="0.1324995"
        ry="7.4083333"
      />
      <text
        x="24"
        y="17"
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{ fontSize: "12px", fontFamily: "Roboto" }}
      >
        {oddsText}
      </text>
    </svg>
  );
};

export const SVGWithOddsStar = ({ odds, oddsFormat }) => {
  let oddsText = "";

  if (oddsFormat === "decimal") {
    /* oddsText = odds.toFixed(2); */
    oddsText = odds;
  } else if (oddsFormat === "american") {
    oddsText = `${decimalToAmerican(odds)}`;
  }

  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 13.193076 12.624632"
      version="1.1"
      id="svg207"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs204" />
      <path
        style={{
          fill: "#ffd700",
          fillOpacity: 1,
          stroke: "#ffd700",
          strokeWidth: 0.265,
          strokeOpacity: 0.8,
        }}
        id="path356"
        d="M 13.494158,13.616461 9.6259517,10.83992 5.8657374,13.761048 7.3110439,9.224167 3.3709159,6.5506689 8.1323704,6.5232637 9.4574516,1.949823 10.954886,6.4697666 15.713959,6.3167229 11.87797,9.1376069 Z"
        transform="translate(-2.9432827,-1.502935)"
      />
      <text
        x="6.6"
        y="6"
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{ fontSize: "3px", fontFamily: "Roboto" }}
      >
        {oddsText}
      </text>
    </svg>
  );
};
