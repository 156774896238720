import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "./Predictions.css";
import { useState } from "react";
import ReadMoreLess from "../components/ReadMoreLess/ReadMoreLess";
import {
  SVGWithOddsCircle,
  SVGWithOddsTriangle,
  SVGWithOddsSquare,
  SVGWithOddsRect,
  SVGWithOddsStar,
} from "../components/OddsSVG/OddsSVG";
import statistics from "../assets/images/others/statistics.png";

function Predictions() {
  const [oddsFormat, setOddsFormat] = useState("decimal");
  const [currency, setCurrency] = useState("USD");
  let exchangeRate = 0.924;
  return (
    <div className="predictions-container">
      <Navbar />
      <div className="predictions-content">
        <div id="solutions">
          <ReadMoreLess maxParagraph={3}>
            <h1>Solutions</h1>
            <p>
              Winperks' Predictions is your pioneering prediction system and
              go-to source for data-driven analysis of sports games.You are
              welcome to access reliable advice and accurate information for
              sports betting. Our advanced algorithms and cutting-edge
              technology provide highly accurate predictions for all major
              sports leagues. Whether you're a seasoned bettor or a casual sports fan,
              our predictions will give you insight into the possible outcome of
              chosen games and help you make informed decisions.
            </p>
            <p>
              We know that sports wagering can be complex and challenging, hence
              we strive to make our predictions simple and accessible. You can
              quickly and easily access the information you need, including odds
              of renowned bookmakers. Whether you're looking to place
              <strong> a bet on other platforms</strong> or simply want to stay
              informed, our predictions are an invaluable resource. With our
              leading system, you'll have the most current information at your
              fingertips. We work tirelessly to ensure that our predictions are
              accurate and winning. Our ultimate goal is to help you make good
              choices. Discover how our data-driven analysis can give you an
              edge in the world of sports betting.
            </p>
            <p>
              Please consider that some predictions may be affected by
              unforeseen events or variables, such as injuries, weather
              conditions, or other circumstances that can affect the result of a
              game.
            </p>
            <h3>Sports betting simplified</h3>
            <p>
              While some may find sports betting to be a form of entertainment
              and a means to engage with their favorite teams and sports,
              informative betting may become tedious if it introduces an
              excessive amount of data and analysis. Therefore, we simplified
              the information process and customized our clear and concise
              predictions for your best decisions. We prefer to keep things
              straightforward when it comes to predicting the outcome of games.
            </p>

            <p>
              However, betting can be a controversial topic. We want to make it
              clear that we do not promote betting in any way. We do not
              encourage or advertise the act of placing bets, and we fully
              respect the laws and regulations regarding betting in any region.
            </p>
            <p>
              At Winperks, we do not promote betting, but we do believe that by
              providing informative and reliable predictions, we can help to
              make sports betting more secure and enjoyable for individuals who
              opted for it.
            </p>
            <p>
              We believe that providing customized predictions for our
              customers, tailoring our analysis and insights to each
              individual's wagering preferences and interests, makes the process
              more engaging and profitable.Good luck!
            </p>
          </ReadMoreLess>
        </div>
        <div id="pricing">
          <h1>Pricing</h1>
          <p>
            Our pricing model at Winperks is based on the resulting total odd of
            predictions you will access. We offer different pricing tiers to
            suit the needs of our customers. Here's a breakdown of the different
            tiers and their associated prices:
          </p>
          <div className="pricing-grid-container">
            <table className="price-table">
              <thead>
                <tr>
                  <th>
                    Total Resulting Odd
                    <div className="odds__radio">
                      <input
                        type="radio"
                        name="oddsFormat"
                        value="decimal"
                        checked={oddsFormat === "decimal"}
                        onChange={(e) => setOddsFormat(e.target.value)}
                      />
                      Decimal
                      <input
                        type="radio"
                        name="oddsFormat"
                        value="american"
                        checked={oddsFormat === "american"}
                        onChange={(e) => setOddsFormat(e.target.value)}
                      />
                      American
                    </div>
                  </th>
                  <th>
                    Price
                    <div className="odds__radio">
                      <input
                        type="radio"
                        name="price"
                        value="USD"
                        checked={currency === "USD"}
                        onChange={(e) => setCurrency(e.target.value)}
                      />
                      USD
                      <input
                        type="radio"
                        name="price"
                        value="EUR"
                        checked={currency === "EUR"}
                        onChange={(e) => setCurrency(e.target.value)}
                      />
                      EUR
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SVGWithOddsCircle odds={2} oddsFormat={oddsFormat} />
                  </td>
                  <td>
                    {currency === "USD"
                      ? `$5`
                      : `€` + Math.round(5.0 * exchangeRate * 1e2) / 1e2}
                  </td>
                </tr>
                <tr>
                  <td>
                    <SVGWithOddsTriangle odds={5} oddsFormat={oddsFormat} />
                  </td>
                  <td>
                    {currency === "USD"
                      ? `$13`
                      : `€` + Math.round(13.0 * exchangeRate * 1e2) / 1e2}
                  </td>
                </tr>
                <tr>
                  <td>
                    <SVGWithOddsSquare odds={10} oddsFormat={oddsFormat} />
                  </td>
                  <td>
                    {currency === "USD"
                      ? `$28`
                      : `€` + Math.round(28.0 * exchangeRate * 1e2) / 1e2}
                  </td>
                </tr>
                <tr>
                  <td>
                    <SVGWithOddsRect odds={20} oddsFormat={oddsFormat} />
                  </td>
                  <td>
                    {currency === "USD"
                      ? `$60`
                      : `€` + Math.round(60.0 * exchangeRate * 1e2) / 1e2}
                  </td>
                </tr>
                <tr>
                  <td>
                    <SVGWithOddsStar odds={50} oddsFormat={oddsFormat} />
                  </td>
                  <td>
                    {currency === "USD"
                      ? `$125`
                      : `€` + Math.round(125.0 * exchangeRate * 1e2) / 1e2}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="pi-container">
              <p>
                This pricing model is based on a one-time charge for each tier.
                Customers can choose their desired total odd of predictions they
                need, and pay the associated one-time fee. This allows you to
                access the predictions you need for your preferred sport without
                committing to a long-term contract or recurring charges.
              </p>
              <img src={statistics} alt="statistics fun trophy"></img>
            </div>
          </div>
        </div>
        <div id="refund">
          <ReadMoreLess maxParagraph={3}>
            <h1>Refund</h1>
            <p>
              Our refund policy at Winperks reflects our commitment to sharing
              the risk associated with sports wagering. It is designed to
              provide you with peace of mind and confidence in our predictions.
              If a prediction made by us is not correct, we offer a 90% refund
              on the one-time fee charged for that prediction.
            </p>
            <p>
              The refund process is automatic upon request, within 14 days of
              the prediction being faulty. Customers must be certain that the
              prediction was incorrect before requesting a refund. We do not
              compare results with other sources, so you must rely on your own
              judgment to determine predictions results.
            </p>
            <p>
              The refund is only applicable when the prediction is not correct.
              Our analysis are based on a combination of data, and expertise,
              and we stand behind the accuracy of our predictions. We want to
              ensure our customers are satisfied with the service they receive.
            </p>
            <p>
              Please note that requesting a refund for a correct prediction
              might subject the user to legal charges, as it is considered as
              fraud. Our confidence in the accuracy of our predictions leads us
              to stand firmly behind them, and we believe that our service will
              meet your expectations.
            </p>
          </ReadMoreLess>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Predictions;
