import { useState } from "react";
import { FormInput, Alert } from "../../components";
import { useAppContext } from "../../context/appContext";
import "./Profile.css";
import MR from "../../assets/images/others/Mr.png";
import MS from "../../assets/images/others/Ms.png";

const Profile = () => {
  const {
    user,
    showAlert,
    displayAlert,
    updateUser,
    isLoading,
    deleteAccount,
  } = useAppContext();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [location, setLocation] = useState(user?.location);
  const [birthday, setBirthday] = useState(user?.birthday);
  const [title, setTitle] = useState(user?.title);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !birthday || !location || !title) {
      displayAlert();
      return;
    }

    updateUser({ firstName, lastName, location, birthday, title });
  };
  const handleDeleteAccount = () => {
    deleteAccount(user?._id);
  };
  const openConfirmation = () => {
    setShowConfirmation(true);
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
  };
  return (
    <>
      <section className="profile">
        <form className="form" onSubmit={handleSubmit}>
          {showAlert && <Alert />}

          <div className="form-center">
            <div className="title">
              <label className="title-label">Title:</label>
              <label>
                <input
                  id="Mr"
                  type="radio"
                  value="Mr"
                  checked={title === "Mr"}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                Mr.
              </label>
              <label>
                <input
                  id="Ms"
                  type="radio"
                  value="Ms"
                  checked={title === "Ms"}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                Ms.
              </label>
            </div>
            <FormInput
              id="firstName"
              label="First Name"
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              pattern="^(?=.{1,30}$)[\p{L}']+([\p{Zs}\p{L}'-.]*[\p{L}']+)?$"
              errorMessage="Please, write your first name!"
            />
            <FormInput
              id="lastName"
              label="Last Name"
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              errorMessage="Please, write your last name!"
              pattern="^(?=.{1,30}$)[\p{L}']+([\p{Zs}\p{L}'-.]*[\p{L}']+)?$"
            />
            <FormInput
              id="email"
              label="Email"
              type="email"
              name="email"
              value={user?.email}
              readOnly={true}
              autoComplete="off"
            />

            <FormInput
              id="address"
              label="Address"
              type="text"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              autoComplete="street-address"
            />
            <FormInput
              id="birthday"
              label="Birthday"
              type="date"
              name="birthday"
              value={birthday.slice(0, 10)}
              onChange={(e) => setBirthday(e.target.value)}
              errorMessage="You must be 18 years or older to continue"
            />
            <button
              className="btn btn-block"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Please Wait..." : "Save Changes"}
            </button>
            {/* <button
            className="btn btn-block"
            disabled={isLoading}
            onClick={() => {
              handleDeleteAccount();
            }}
          >
            {isLoading ? "Please Wait..." : "Delete Account"}
          </button> */}
          </div>
          <div></div>
        </form>

        {title && (
          <div className="profile-img">
            <img src={title === "Mr" ? MR : MS} alt={title} />
          </div>
        )}
      </section>
      {!showConfirmation && (
        <button className="confirm btn" onClick={() => openConfirmation()}>
          Delete My Account
        </button>
      )}
      {showConfirmation && (
        <div>
          <p className="confirmation">
            Are you sure you want to delete your account?This action cannot be
            undone.
          </p>
          <button className="delete btn" onClick={() => handleDeleteAccount()}>
            Yes, delete my account permanently
          </button>
          <button className="cancel btn" onClick={() => closeConfirmation()}>
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default Profile;
