import React from "react";
import ReadMoreLess from "../components/ReadMoreLess/ReadMoreLess";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "./About.css";
function About() {
  return (
    <div className="about-container">
      <Navbar />

      <div className="about-content">
        <div id="about">
          <ReadMoreLess maxParagraph={4}>
            <h1>About</h1>
            <p>
              At Winperks, we are committed to providing a service that is built
              on a foundation of integrity, reliability, and excellence, among
              others. These values guide everything we do and are our driving
              force.
            </p>
            <p>
              We have simplified and enhanced the sports betting experience for
              our customers. By providing accurate and customized predictions, a
              clear and transparent pricing structure, and refunds for faulty
              predictions, we aim to reduce the uncertainty associated with
              sports betting and make it more enjoyable and reliable.
            </p>
            <p>
              Sports wagering can be exciting, especially when you are engaging
              with your favorite teams and sports, but it can also be risky and
              stressful. However, by providing a more secure and informative
              venture, we offer a more lucrative and pleasant experience for
              everyone.
            </p>
            <p>
              Despite the inherent uncertainty of sports betting, we believe
              that informative betting can help to mitigate it. On our platform,
              we are sharing the risk associated with betting by refunding
              faulty predictions. This way, you may place your bets on other
              platforms with confidence and enjoy the thrill of the game.
            </p>
            <p>
              It is now easier than ever to access predictions and track your
              performance. Stay up-to-date on the latest possible outcomes.
            </p>
          </ReadMoreLess>
        </div>
        <div id="mission">
          <ReadMoreLess maxParagraph={4}>
            <h1>Mission</h1>
            <p>
              At Winperks, our mission is to provide a valuable service to
              sports bettors worldwide by offering customized predictions based
              on our data analysis. We believe that the more informed a bettor
              is, the more confident and successful they will be in their
              betting decisions.
            </p>
            <p>
              By providing accurate and up-to-date information, we aim to make
              the sports betting experience better for everyone. We can help to
              reduce the risks associated with betting.
            </p>
            <p>
              Wagering involves risking money in hopes of winning more, but it
              does not guarantee a return. It is important to note that it is a
              form of entertainment, but it should not be considered as a
              reliable source of income or investment. With Winperks, we aim to
              provide more informed and secure options to enhance the overall
              betting experience.
            </p>
            <p>
              Finally, we strive to become the guide that provides a layer of
              reliability for our customers.You can trust our predictions and
              pursue a redefined betting process. Join us for a better way of
              sports betting!
            </p>
          </ReadMoreLess>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
