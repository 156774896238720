import "./ForgotPassword.css";
import { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = `/api/v1/reset-password`;
      const { data } = await axios.post(url, { email });
      setMsg(data.message);
      setError("");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
        setMsg("");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-pw">
      <form onSubmit={handleSubmit}>
        <h1>Forgot Password</h1>
        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        {error && <div className="error_msg">{error}</div>}
        {msg && <div className="success_msg">{msg}</div>}
        <button type="submit" className="green_btn" disabled={isLoading}>
          {isLoading ? "Sending..." : "Send Reset"}
        </button>

        <NavLink className="home__link" to="/home">
          Back Home
        </NavLink>
      </form>
    </div>
  );
};

export default ForgotPassword;
