import "./Navbar.css";

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../Logo/Logo";

const Menu = () => {
  return (
    <>
      <ul id="navbar">
        <li>
          <NavLink activeclassname="active" end to="/home">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/predictions">
            Predictions
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/about">
            About
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/help">
            Help
          </NavLink>
        </li>
      </ul>
    </>
  );
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <nav className="Navbar">
      <Logo classname="logo" />
      <div className="navbar__container">
        <Menu />
      </div>
      <div className="navbar__menu">
        {toggleMenu ? (
          <FaTimes
            color="#ff4500"
            size={32}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <FaBars
            color="#ff4500"
            size={32}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar__menu-container scale-up-center">
            <div className="navbar__menu-links">
              <Menu />
              <div className="navbar__menu-links-sign">
                <NavLink className="menu-links-sign-in__button" to="/login">
                  Log In
                </NavLink>
                <NavLink className="menu-links-sign-up__button" to="/signup">
                  Sign Up
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="navbar-sign">
        <NavLink
          className="navbar-sign__button navbar-sign__button--log-in"
          to="/login"
        >
          Log In
        </NavLink>
        <NavLink
          className="navbar-sign__button navbar-sign__button--sign-up"
          to="/signup"
        >
          Sign Up
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
