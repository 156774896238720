import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const AreaChartComponent = ({ data }) => {
  return (
    <div style={{ backgroundColor: "#fff", padding: "20px", margin: "10px" }}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data} margin={{ top: 50 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#660099" />
          <XAxis dataKey="date" stroke="#660099" />
          <YAxis allowDecimals={false} stroke="#660099" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="refunded"
            stackId="b"
            stroke="#ff0080"
            fill="#ff0080"
          />
          <Area
            type="monotone"
            dataKey="successful"
            stackId="b"
            stroke="#0f5132"
            fill="#0f5132"
          />
          <Area
            type="monotone"
            dataKey="pending"
            stackId="b"
            stroke="#FFD700"
            fill="#FFD700"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChartComponent;
