import "./EmailVerify.css";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import success from "../../assets/images/others/success-email.png";
import { Error } from "../../pages";
const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(null);
  const param = useParams();
  const verifyEmailUrl = async () => {
    try {
      const url = `/api/v1/auth/${param.id}/verify/${param.token}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        // console.log(response);
        // console.log(validUrl);
        setValidUrl(true);
      } else {
        setValidUrl(false);
      }
    } catch (error) {
      console.error("Verification failed:", error);
      setValidUrl(false);
    }
  };

  useEffect(() => {
    // return () => verifyEmailUrl();
    verifyEmailUrl();
  }, []);
  //[param]
  if (validUrl === null) {
    return null;
  }

  return (
    <>
      {validUrl ? (
        <div className="verif-container">
          <img src={success} alt="success_img" />
          <h1>Email verified successfully</h1>
          <Link to="/login">
            <button type="button" className="green_btn">
              Login
            </button>
          </Link>
        </div>
      ) : (
        <Error />
      )}
    </>
  );
};

export default EmailVerify;
