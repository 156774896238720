import React, { useEffect, useState } from "react";
import FormInput from "../components/FormInput/FormInput";
import "./SignUp.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import Alert from "../components/Alert/Alert";
// import ReCAPTCHA from "react-google-recaptcha";
import { Loading } from "../components";
import SignUpFigure from "../assets/images/others/sign-up.png";
import Logo from "../assets/icons/WinperksLogo.svg";

const SignUp = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    showAlert,
    displayAlert,
    signupUser,
    userCreated,
    setRedirectedTrue,
    redirected,
    clearAlert,
  } = useAppContext();
  const [alertClear, setAlertClear] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    password: "",
    confirmPassword: "",
  });
  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      errorMessage: "Please, write your first name!",
      label: "First Name",
      autoComplete: "given-name",
      pattern: "^(?=.{1,30}$)[\\p{L}']+([\\p{Zs}\\p{L}'-.]*[\\p{L}']+)?\\s?$",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      errorMessage: "Please, write your last name!",
      label: "Last Name",
      autoComplete: "family-name",
      pattern: "^(?=.{1,30}$)[\\p{L}']+([\\p{Zs}\\p{L}'-.]*[\\p{L}']+)?\\s?$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      autoComplete: "email",
      required: true,
    },
    {
      id: 4,
      name: "birthday",
      type: "date",
      placeholder: "Birthday",
      errorMessage: "You must be 18 years or older to sign up",
      label: "Birthday",
      required: true,
    },
    {
      id: 5,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8 to 20 characters and include at least 1 letter and 1 number",
      label: "Password",
      pattern: "^(?=.*\\p{L})(?=.*\\p{N}).{8,20}$",
      required: true,
    },
    {
      id: 6,
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
    },
  ];
  // const recaptchaRef = useRef();
  const handleCheckboxChange = (e) => {
    setAgreeToTerms(e.target.checked);
  };
  const handleMarketingChange = (e) => {
    setMarketing(e.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, birthday, confirmPassword } =
      values;
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !birthday ||
      !confirmPassword
    ) {
      displayAlert();
    }
    // const recaptchaToken = await recaptchaRef.current.executeAsync();
    // recaptchaRef.current.reset();
    const currentUser = {
      firstName,
      lastName,
      email,
      birthday,
      password,
      marketing,
    };
    signupUser({ ...currentUser });
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (userCreated && !redirected) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      setAlertClear(true);
      setRedirectedTrue();
    } else if (redirected && !alertClear) {
      clearAlert();
      setAlertClear(true);
    }
  }, [userCreated, redirected]);

  return (
    <section className="sign-up">
      <div className="signUp-logo">
        <img src={Logo} alt="winperks" />
      </div>
      <div>
        <div className="sign-up-logo">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <h1>Sign Up</h1>
              {showAlert && <Alert />}
              {isLoading ? (
                <Loading center={true} />
              ) : (
                <>
                  {inputs.map((input) => (
                    <FormInput
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  ))}
                  {/* <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfqVxkpAAAAABsTCmQUpw0u5M8RnXkTEQn0fWcy"
              size="invisible"
            /> */}

                  <div className="terms__label">
                    <label>
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={agreeToTerms}
                        onChange={handleCheckboxChange}
                      />
                      I agree to the{" "}
                      <NavLink
                        className="terms__link"
                        target="_blank"
                        to="/terms"
                      >
                        terms and conditions
                      </NavLink>{" "}
                      and{" "}
                      <NavLink
                        className="terms__link"
                        target="_blank"
                        to="/privacy"
                      >
                        privacy policy
                      </NavLink>
                      .
                    </label>
                  </div>
                  <div className="terms__label">
                    <label>
                      <input
                        type="checkbox"
                        name="agreeToMarketing"
                        checked={marketing}
                        onChange={handleMarketingChange}
                      />
                      Would you like to receive promotional content?
                    </label>
                  </div>
                </>
              )}

              <button type="submit" disabled={isLoading || !agreeToTerms}>
                Submit
              </button>
              <p>
                Already have an account?
                <NavLink className="log-in__link" to="/login">
                  Log In
                </NavLink>
              </p>
            </form>
          </div>
          <div className="vl"></div>
          <div className="image-container">
            <img
              src={SignUpFigure}
              alt="diamond shiny dices"
              className="sign-up-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
