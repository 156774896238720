import { PredictionsContainer, SearchContainer } from "../../components";
function MyPredictions() {
  return (
    <>
      <SearchContainer />
      <PredictionsContainer />
    </>
  );
}

export default MyPredictions;
