import React from "react";
import "./Terms.css";
const Terms = () => {
  return (
    <main className="terms">
      <h1>Terms and Conditions</h1>
      <section id="t00">
        <h1>*IMPORTANT INFORMATION*</h1>
        <h1>PLEASE READ THIS INFORMATION CAREFULLY</h1>
        <p>
          <strong>
            Everything contained in these Terms (see Section 1.2 below for how
            "Terms" is defined) is important and should be read by you, but we
            would like to draw your attention in particular to the following
            Sections in these General Terms and Conditions (“General Terms and
            Conditions” is defined in Section 1.2 below) and the important terms
            and conditions that they contain. These Sections in particular have
            the potential to affect your interests. It is important that you
            read the Sections themselves and not rely on the short summaries
            below:
          </strong>
        </p>
        <p>
          <strong>
            By registering for an Account (defined in Section 4.1 below) and/or
            accessing or using any part of the Services (defined in Section 1.1
            below), you are accepting these terms and conditions.
          </strong>{" "}
          This agreement is between you and us and it incorporates all the
          terms, conditions, rules and documents forming the “Terms” (explained
          and defined in Section 1.2 below). Please read Section 1 carefully.
        </p>
        <p>
          <strong>
            You must be 18 or over and meet certain other eligibility criteria
            and requirements to register for an Account and use the Services. It
            is also important that you determine whether your use of the
            Services is illegal in your home jurisdiction or (if different) your
            current location, and you may not use the Services if this is the
            case.
          </strong>{" "}
          Please read Section 4 carefully.
        </p>{" "}
        <p>
          <strong>
            We will need to complete our Verification Checks (defined in Section
            6.1) before you can do certain things in connection with your
            Account.
          </strong>{" "}
          We may also run Verification Checks on an ongoing basis after your
          Account has been opened. Please read Section 6 carefully.
        </p>{" "}
        <p>
          <strong>You may only register for one Account.</strong> If we discover
          that you have opened and/or operated more than one Account (which we
          call a <strong>“Duplicate Account”</strong>), we will have certain
          rights, including the right to close your Duplicate Account(s) (and in
          some circumstances your original Account too) and to void Transactions
          (defined in Section 4.1 below). Please read Section 8 carefully.
        </p>{" "}
        <p>
          <strong>We do not have to offer Predictions.</strong> We are entitled
          to decline to accept all, or part, of any Transaction (defined in
          Section 4.1). If we decline all or part of a Transaction, we may offer
          a different/amended Transaction in place of the declined part of the
          Transaction. We do not have to tell you our reasons for taking these
          actions. Please read Section 11.1 carefully.
        </p>{" "}
        <p>
          <strong>
            Refund: You will usually be refunded for faulty Predictions upon
            request,
          </strong>{" "}
          but there are certain circumstances in which we will be entitled to
          delay or withhold funds. Please read Section 11 carefully.
        </p>
        <p>
          <strong>
            We will not tolerate fraudulent activity, cheating or any other
            “Prohibited Activities” (defined in Section 12 below).
          </strong>{" "}
          If you do any of these things, we will have certain rights, including
          the right to suspend and, ultimately, close your Account, to void
          relevant Transactions and/or to withhold funds (including lost
          predictions). Please read Section 12 and Section 13 carefully.
        </p>{" "}
        <p>
          <strong>Errors, Malfunctions, and Interruptions:</strong> If an Error,
          Malfunction, or Interruption occurs in any part of the Services (as
          each of those terms is defined in Section 18 below), we have certain
          rights including (but not limited to) the right to suspend the
          affected Prediction while we investigate and/or void affected
          Transactions. This includes circumstances where the problem is not
          obvious to you or us and is only discovered by us when we check or
          verify the result of a Transaction and/or Prediction retrospectively.
          Please read Section 17 carefully.
        </p>{" "}
        <p>
          <strong>
            In certain circumstances, our liability to you is limited or
            excluded: When you access and use the Service, It is also important
            that you understand that we will not be liable to you under these
            Terms, nor do we owe you a duty of care, in respect of our
            compliance or otherwise with any regulatory requirements that may
            apply to us from time to time.
          </strong>{" "}
          Please read Section 18 carefully.
        </p>{" "}
        <p>
          <strong>
            We may sometimes take actions in relation to your Account for legal
            and/or regulatory reasons including where required to do so by the
            terms of a regulatory agency.
          </strong>{" "}
          These actions include running certain Verification Checks, restricting
          or closing your Account. Please read Sections 6 and 13.5 carefully.{" "}
        </p>
      </section>
      <section id="t01">
        <h2 className="contents-terms">Contents</h2>

        <nav>
          <ul>
            <li>
              <a href="#1">1. Introduction</a>
            </li>
            <li>
              <a href="#2">2. Who We Are</a>
            </li>
            <li>
              <a href="#3">3. Changes To These Terms and/or the Services </a>
            </li>
            <li>
              <a href="#4">4. Account Eligibility and Registration </a>
            </li>
            <li>
              <a href="#5">5. Account Opening </a>
            </li>
            <li>
              <a href="#6">6. Account Verification </a>
            </li>
            <li>
              <a href="#7">7. Account Security </a>
            </li>
            <li>
              <a href="#8">8. Duplicate Accounts </a>
            </li>
            <li>
              <a href="#9">9. Purchase and Currency </a>
            </li>
            <li>
              <a href="#10">10. Refund</a>
            </li>
            <li>
              <a href="#11">11. Transactions and Transaction Confirmation </a>
            </li>
            <li>
              <a href="#12">12. Prohibited Activities </a>
            </li>
            <li>
              <a href="#13">
                13. Suspension, Limitation, and Closure of Your Account by Us{" "}
              </a>
            </li>
            <li>
              <a href="#14">14. Deleting Your Own Account</a>
            </li>
            <li>
              <a href="#15">15. Software and Technology</a>
            </li>
            <li>
              <a href="#16">16. Third Party Content </a>
            </li>
            <li>
              <a href="#17">17. Malfunctions, Errors and Interruptions </a>
            </li>
            <li>
              <a href="#18">18. Limitation of Liability</a>
            </li>{" "}
            <li>
              <a href="#19">19. Responsible Gaming</a>
            </li>{" "}
            <li>
              <a href="#20">20. Intellectual Property</a>
            </li>{" "}
            <li>
              <a href="#21">21. Privacy Policy</a>
            </li>
            <li>
              <a href="#22">22. Other Important Legal Terms</a>
            </li>
          </ul>
        </nav>
      </section>
      <h2>Terms and Conditions</h2>
      <ul>
        <li>
          <section id="1">
            <h2>1. Introduction</h2>
            <ul>
              <li>
                <p>
                  1.1   When you register for an Account with us ({" "}
                  <strong>“Account”</strong> is defined in Section 4.1 below)
                  and/or use or access any of the predictions, products, or
                  services on our website, (collectively the{" "}
                  <strong>“Services”</strong>), you will be accepting our terms
                  and conditions.
                </p>
              </li>
              <li>
                <p>
                  1.2   These General Terms and Conditions are the legal
                  agreements between you and us, and you should read and
                  understand them in order to use our services. You must agree
                  to act in accordance with these terms before registering an
                  account.{" "}
                </p>
              </li>
              <li>
                <p>
                  1.3   Please read the Terms carefully before registering for
                  an Account or otherwise accessing any part of the Services. If
                  you do not accept the Terms, you may not use, visit or access
                  any part of the Services.{" "}
                </p>
              </li>
            </ul>
          </section>
        </li>
        <li>
          <setion id="2">
            <h2>2. Who We Are</h2>
            <ul>
              <li>
                <p>
                  2.1   References in these Terms to “Winperks”, “we”, "our " or
                  “us” to:
                </p>
              </li>
              <li>
                <p>
                  <strong>Winperks</strong> is a sports predictions platform
                  operating under the URL : www.winperks.info
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="3">
            <h2>3. Changes To These Terms and/or the Services</h2>
            <ul>
              <li>
                <h3>Changes to these Terms </h3>
              </li>
              <li>
                <p>
                  3.1   We may change the Terms from time to time for a number
                  of reasons, including to comply with applicable laws and
                  regulations, to reflect changes to the Services, for security
                  purposes, and/or to reflect changes in our business practices
                  or operations.
                </p>
              </li>
              <li>
                <p>
                  3.2   Minor or insubstantial changes may be made at any time
                  and will become effective immediately upon posting to this (or
                  any other relevant) page and accordingly you are advised to
                  review the Terms on a regular basis.
                </p>
              </li>
              <li>
                <p>
                  3.3   Material changes will be notified to you reasonably in
                  advance of such changes taking effect, either by means of an
                  in-Service notification or via email to your registered email
                  address.
                </p>
              </li>
              <li>
                <p>
                  3.4   If you do not agree to any change in the Terms, you
                  should immediately stop using the Services and close your
                  Account. If you continue to use the Services after the date on
                  which the changes to the Terms come into effect, you will be
                  deemed to have accepted those changes.
                </p>
              </li>
              <li>
                <h3>Changes to the Services </h3>
              </li>
              <li>
                <p>
                  3.5   We may make changes to, or modify, correct, suspend, add
                  to, remove from, or discontinue any aspect of the Services (or
                  any part of them) from time to time without liability to you.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="4">
            <h2>4. Account Eligibility and Registration</h2>
            <ul>
              <li>
                <p>
                  4.1   To use the Services and to purchase Predictions and/or
                  similar transactions (collectively “Transactions”), you must
                  first register for an Account with us (“Account”).
                </p>
              </li>
              <li>
                <p>
                  4.2   To be eligible to open an Account and use the Services,
                  you must meet all of the following criteria and by registering
                  for an Account you acknowledge that you:
                </p>
                <ul>
                  <li>
                    <p>
                      {" "}
                      (a)   are over 18 years of age and of legal capacity to
                      enter into these Terms and use the Services;
                    </p>
                  </li>
                  <li>
                    <p>
                      (b)   are acting solely for your own personal use in
                      connection with your Account and your use of the Services
                      (i.e., you are not acting on behalf of any other person or
                      entity);
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      (c)   are not prohibited for any reason from using the
                      Services;{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      (d)   do not already have an Account with us (see Section
                      8 below for more information about Duplicate Accounts);
                    </p>
                  </li>
                  <li>
                    <p>
                      (e)   there is a risk of losing money when using our
                      Service to support you on any sports betting platform, and
                      you are responsible for any such loss;
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      (f)   have not previously had an Account with us which we
                      or you have restricted or closed for any reason.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  4.3   Access and/or use of the Services may be illegal for
                  people who are residents of, or are located in, certain
                  countries. You are solely responsible for determining whether
                  your access and/or use of the Services is prohibited by
                  applicable laws in your home country or (if different) in your
                  location or your jurisdiction and you may not access and/or
                  use the Services if this is the case.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="5">
            <h2>5. Account Opening</h2>
            <ul>
              <li>
                <p>
                  5.1   To register for an Account, you must complete the
                  required registration form by providing certain personal
                  information.
                </p>
              </li>
              <li>
                <p>
                  5.2   All information supplied by you when registering for an
                  Account must be complete, accurate, and not misleading in all
                  respects.
                </p>
              </li>
              <li>
                <p>
                  5.3   You must keep your registration and Account details up
                  to date. This, and your Account information, may be amended in
                  the “Profile” section when logged into your Account or by
                  contacting us. It is particularly important that you update us
                  about any information that affects your use of the Services
                  (for example email) as quickly as possible. If you require any
                  assistance, please{" "}
                  <a class="anchor" href="mailto:support@winperks.info">
                    Contact Us
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  5.4   By registering for an Account, you acknowledge and
                  confirm to us that you meet the eligibility criteria in
                  Section 4 above and that all information you provide when
                  registering for an Account (and subsequently) is complete,
                  accurate, and not misleading.
                </p>
              </li>
              <li>
                <p>
                  5.5   We are not obliged to accept the registration for an
                  Account.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="6">
            <h2>6. Account Verification</h2>
            <ul>
              <li>
                <p>
                  6.1   We may undertake certain checks to comply with our legal
                  and regulatory obligations – for example to verify your age,
                  identity, address, location, and the source of your funds –
                  and we may also undertake other credit, fraud prevention and
                  verification checks (collectively{" "}
                  <strong> “Verification Checks”</strong>).
                </p>
              </li>
              <li>
                <p>
                  6.2   You must be 18 years of age or older in order to
                  register for an Account.
                </p>
              </li>
              <li>
                <p>
                  6.3   By registering for an Account, you acknowledge and agree
                  that we are entitled to conduct any Verification Checks that
                  we may reasonably require and/or are required by applicable
                  laws and regulations. We will carry out Verification Checks,
                  and we may also need to carry out additional or enhanced
                  Verification Checks afterward if certain risk factors arise or
                  change, as required for us to comply with our legal and
                  regulatory obligations. You agree to promptly provide any
                  information or documentation we may reasonably request in
                  relation to such Verification Checks.
                </p>
              </li>
              <li>
                <p>
                  6.4   We will restrict or place limits on your Account in any
                  manner that we deem to be appropriate including by preventing
                  you from accessing the Services, entering into any
                  Transactions. If our Verification Checks cannot be completed
                  to our reasonable satisfaction, your Account will be suspended
                  and, ultimately, may be permanently deleted.{" "}
                </p>
              </li>
              <li>
                <p>
                  6.5   We may supply the information that you have given us to
                  authorized fraud prevention agencies, and/or other authorized
                  third parties for use in identification and credit checks and
                  for fraud detection and prevention purposes. We may also share
                  your information with our regulators. (for more information
                  refer to our{" "}
                  <a class="anchor" target="_blank" href="/privacy">
                    Privacy Policy
                  </a>
                  ).
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="7">
            <h2>7. Account Security</h2>
            <ul>
              <li>
                <p>
                  7.1   From our side, we take advanced security measures and
                  implement a secure privacy policy.
                </p>
              </li>
              <li>
                <p>
                  7.2   You must keep your Login Credentials secret and
                  confidential at all times and never share them with someone
                  else.
                </p>
              </li>
              <li>
                <p>
                  7.3   We recommend that you change your Login Credentials
                  regularly. Your password is hashed and stored securely. If you
                  forget your password, please reset via the available link on
                  the "Log In" page.
                </p>
              </li>
              <li>
                <p>
                  7.4   You are responsible for all Transactions in your Account
                  and any other activities taking place on/via your Account
                  where your Login Credentials have been entered correctly.
                </p>
              </li>
              <li>
                <p>
                  7.5   Your data may be shared for regular operational purposes
                  with entities such as cloud services, data centers, payment
                  services, and customer communication tools.
                </p>
              </li>
              <li>
                <p>
                  7.6   Please note that any information which you transmit to
                  us by email is not encrypted and is transmitted at your own
                  risk.
                </p>
              </li>
              <li>
                <p>
                  7.7   Your personal information will be processed in
                  accordance with our{" "}
                  <a class="anchor" target="_blank" href="/privacy">
                    Privacy Policy
                  </a>
                  .
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="8">
            <h2>8. Duplicate Accounts</h2>
            <ul>
              <li>
                <p>
                  8.1   You may open only one Account. If we discover or have
                  reason to believe that you have opened more than one Account,
                  each such additional Account will be classified as a{" "}
                  <strong>"Duplicate Account"</strong>. We are entitled, in
                  respect of each Duplicate Account:
                </p>
                <ul>
                  <li>
                    <p>
                      (a)  to close such Duplicate Account leaving you with only
                      the original Account (unless we have reason — for example
                      if you have deliberately or fraudulently opened more than
                      one active Account — to close all your Accounts);
                    </p>
                  </li>
                  <li>
                    <p>
                      (b)  to void all unsettled and/or future Transactions on
                      the Duplicate Account.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="9">
            <h2>9. Purchase and Currency</h2>
            <ul>
              <li>
                <h3>Purchase</h3>
              </li>
              <li>
                <p>
                  9.1   You may only make Transactions for the purchase of
                  predictions or services directly via our platform. This means
                  that there are no deposit accounts, and all Transactions must
                  be made at the point of purchase.
                </p>
              </li>
              <li>
                <p>
                  9.2   Further details on how to make purchases can be found by
                  clicking{" "}
                  <a class="anchor" target="_blank" href="/help">
                    here
                  </a>
                  . We are entitled to add, remove, and/or restrict certain
                  payment methods at any time without notice or liability to
                  you.
                </p>
              </li>
              <li>
                <p>
                  9.3   You may only make purchases in your Account from a
                  payment source where you are the named account holder.
                </p>
              </li>
              <li>
                <p>
                  9.4   Transactions will be processed through our payment
                  service providers, ensuring secure and efficient handling of
                  your purchases. You may refer to their terms and conditions
                  for more details.
                </p>
              </li>
              <li>
                9.5   We do not impose a maximum predictions purchase amount per
                day, but our payment processor’s specific maximum transactions
                limits apply. You may refer to the payment service's policies
                for more details.
              </li>
              <li>
                <h3>Currency</h3>
              </li>
              <li>
                <p>
                  9.6   Unless otherwise stated, all financial examples given on
                  the Services are given in either United States Dollars (USD)
                  or Euros (EUR). The prediction price is set in USD; the
                  corresponding price in EUR applies the annual average exchange
                  rate.
                </p>
              </li>
              <li>
                <p>
                  9.7   We accept Transactions in the selected currency. For any
                  other Transactions which require a currency exchange, the
                  applicable exchange rate will be managed by the payment
                  service.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="10">
            <h2>10. Refund</h2>
            <ul>
              <li>
                <p>
                  10.1   If a prediction made by us is incorrect, we offer a
                  ninety percent (90%) refund on the one-time fee charged for
                  that prediction, provided that:
                </p>
                <ul>
                  <li>
                    <p>
                      (a) Refunds are only applicable to predictions that were
                      incorrect. Please note that the prediction could be
                      affected by unforeseen events or variables such as
                      injuries, weather conditions, or other circumstances that
                      can affect the outcome of a game.
                    </p>
                  </li>
                  <li>
                    <p>
                      (b) We do not compare results with other sources;
                      therefore, the customer must rely on their own judgment to
                      determine if a prediction is incorrect. The refund process
                      is automatic upon request, so the customer must be certain
                      that the prediction was incorrect before requesting a
                      refund.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      (c) The customer requests the refund within 14 days of the
                      prediction being faulty.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  10.2   Refund requests can only be made by the registered
                  Account holder who purchased the prediction.
                </p>
              </li>
              <li>
                <p>
                  10.3   Transactions will be processed through the payment
                  service provider, and you will usually be refunded with the
                  same payment method used to purchase a prediction.
                </p>
              </li>
              <li>
                <p>
                  10.4   Requesting a refund for a correct prediction may
                  subject the user to legal charges as fraud. Our confidence in
                  the accuracy of our predictions leads us to stand firmly
                  behind them.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="11">
            <h2>11. Transactions and Transaction Confirmation</h2>
            <ul>
              <li>
                <subsection>
                  <ul>
                    <li>
                      <h3>Transactions </h3>
                    </li>
                    <li>
                      <p>
                        11.1   We are entitled to decline to accept all, or
                        part, of any Transaction requested by you via the
                        Service at our discretion. If we decline all or part of
                        a Transaction, we may — but are not obliged to — offer
                        you a different/amended Transaction in place of the
                        declined part of the Transaction, and you are free to
                        accept or decline this different/amended part of the
                        Transaction. We are not obliged to tell you why we have
                        declined to accept a Transaction or why we have offered
                        a different/amended Transaction.
                      </p>
                    </li>
                    <li>
                      <p>
                        11.2   We only accept Transactions made online.
                        Transactions are not accepted in any other form (post,
                        email, fax, etc.) and where received will be invalid and
                        void.
                      </p>
                    </li>
                    <li>
                      <p>
                        11.3   It is your responsibility to ensure the details
                        of your Transactions are correct.
                      </p>
                    </li>
                  </ul>
                </subsection>
              </li>
              <li>
                <subsection>
                  <ul>
                    <li>
                      <h3>Transaction Confirmation </h3>
                    </li>
                    <li>
                      <p>
                        11.4   Any Transaction that you request online or via
                        our app will be valid only once accepted by our servers
                        and subject to our having received payment in full.
                      </p>
                    </li>
                    <li>
                      <p>
                        11.5   Transactions are not valid until we have received
                        cleared payment in full. Transactions will automatically
                        be void if we have not received the payment.
                      </p>
                    </li>
                    <li>
                      <p>
                        11.6   Should a dispute arise, you and we agree that our
                        Transaction log database will be the ultimate authority
                        in such matters.
                      </p>
                    </li>
                  </ul>
                </subsection>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="12">
            <h2>12. Prohibited Activities</h2>
            <ul>
              <li>
                <p>
                  12.1   You may only use the Services and your Account for
                  lawful sports prediction purposes in accordance with these
                  Terms. You must not engage in any activity which we define
                  below as a “Prohibited Activity”, and you acknowledge and
                  understand that the consequences of you doing so will
                  (depending on the specific circumstances) include the voiding
                  of any relevant Transactions, the withholding of any
                  attributable refund, and the suspension and/or closure of your
                  Account. You agree to compensate us for any costs, charges or
                  losses sustained or incurred by us arising from any Prohibited
                  Activities on your part.
                </p>
              </li>
              <li>
                <p>
                  12.2   The following activities (including any attempt to
                  engage in the following activities) are each defined as a
                  “Prohibited Activity”:
                </p>
                <ul>
                  <li>
                    <p>
                      (a)   if we discover or have reason to believe that your
                      Account is being used for the benefit or on behalf of a
                      third party and/or if you are using the Services other
                      than for your own personal and recreational use (including
                      if you are using any third party’s Account);
                    </p>
                  </li>

                  <li>
                    <p>
                      (b)   if you deliberately attempt to circumvent
                      Responsible Gaming policies and procedures;
                    </p>
                  </li>

                  <li>
                    <p>
                      (c)   if we discover or have reason to believe that you
                      have used the Services in a fraudulent manner and/or for
                      illegal and/or unlawful purposes;
                    </p>
                  </li>

                  <li>
                    <p>
                      (d)   if you repeatedly exceed our daily Account login
                      limit;
                    </p>
                  </li>

                  <li>
                    <p>
                      (e)   if we discover or have reason to believe that you
                      have used the Services in an unfair manner, you have
                      cheated or taken unfair advantage of us, the Services, or
                      any other user of the Services;
                    </p>
                  </li>

                  <li>
                    <p>
                      (f)   if the name on your Account differs from the name on
                      the payment method used to make deposits to your Account;
                    </p>
                  </li>

                  <li>
                    <p>
                      (g)   if we have received a “chargeback”, “denial”,
                      “reversal” and/or "return" notification via a purchase
                      mechanism used on your Account;
                    </p>
                  </li>

                  <li>
                    <p>
                      (h)   if you provide incorrect, incomplete, or misleading
                      information while registering for an Account or afterward
                      (except where you provide any incorrect, incomplete, or
                      misleading information in order to pass relevant
                      age-verification checks).
                    </p>
                  </li>

                  <li>
                    <p>
                      (i)   if we discover or have reason to believe that you
                      have deliberately or fraudulently opened or are using one
                      or more Duplicate Account/s;
                    </p>
                  </li>

                  <li>
                    <p>
                      (j)   if we discover or have reason to believe that you
                      are purchasing with money in your Account which originates
                      from illegal activities;
                    </p>
                  </li>

                  <li>
                    <p>
                      (k)   if you behave in an inappropriate manner towards
                      other users of the Service, our customer support team,
                      and/or other staff members/representatives;
                    </p>
                  </li>

                  <li>
                    <p>
                      (l)   if we discover or have reason to believe that you
                      have used automated systems or software to copy and/or
                      extract the whole or any part of the Service, any
                      information or data on the Services or any part of it
                      (including, but not limited to, predictions, statistics,
                      sporting data, fixture lists and odds), or any other
                      information or data on or contained within or as part of
                      the Services and/or its source code (such as as ‘screen
                      scraping’ or ‘scraping’);
                    </p>
                  </li>

                  <li>
                    <p>
                      (m)   if we discover or have reason to believe that you
                      have sought to make commercial use of the whole or any
                      part of the Services, the information or data on the
                      Services (including, but not limited to, predictions,
                      statistics, sports data, fixture lists and odds), or any
                      other information or data on the Services and/or its
                      source code;
                    </p>
                  </li>

                  <li>
                    <p>
                      (n)   if we discover or have reason to believe that you
                      have in any way interfered, interrupted, or manipulated
                      the operation of the Services or the normal running of any
                      of the Services;
                    </p>
                  </li>

                  <li>
                    <p>
                      (o)   if we discover or have reason to believe that you
                      have exploited or participated in the exploitation of a
                      fault, loophole or error (including any Error, Malfunction
                      or Interruption as defined in Section 17 below) in the
                      Services and/or in our or any third party's software;
                    </p>
                  </li>

                  <li>
                    <p>
                      (p)   if we discover or have reason to believe that you
                      have, or have attempted to, sell, transfer and/or acquire
                      any Account/s from other customers; and
                    </p>
                  </li>

                  <li>
                    <p>
                      (q)   if you seriously breach these Terms in some other
                      way.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="13">
            <h2>
              13. Suspension, Limitation, and Closure of Your Account by Us
            </h2>
            <ul>
              <li>
                <p>
                  13.1   We may close, restrict, limit, or suspend your Account
                  at any time and without notice except as set out below in this
                  Section 13.
                </p>
              </li>
              <li>
                <p>
                  13.2   We may notify you of an Account closure by us, except
                  where we discover or suspect (acting reasonably) that you have
                  engaged in a Prohibited Activity, in which case we are
                  entitled to take such action without notice following the
                  process set out below.
                </p>
              </li>
              <li>
                <p>
                  13.3   If we suspect any Prohibited Activity, we will promptly
                  limit or suspend your Account without notice. Your Account
                  will remain restricted during our investigation, and you agree
                  to cooperate fully. We aim to investigate promptly, but delays
                  in your responses may affect our process.
                </p>
              </li>
              <li>
                <p>
                  13.4   If our investigation results in our decision that the
                  issue has been resolved to our satisfaction, we will lift the
                  Account suspension. If our investigation results in our
                  decision that you have engaged in any Prohibited Activity, we
                  will permanently close your Account. In these circumstances,
                  your details may be passed on to an applicable regulatory
                  agency or any other relevant external third parties.
                </p>
              </li>
              <li>
                <p>
                  13.5   We may also from time to time be required to restrict,
                  suspend or close Accounts for legal or regulatory reasons,
                  including where we are required to do so by a supervisory
                  authority and/or the terms of this agreement. Such actions, if
                  applicable, may affect your use of your Account.
                </p>
              </li>
              <li>
                <p>
                  13.6   If we close your Account, any potential refunds in your
                  Account at the time of such closure by us will be paid back to
                  you, except when you have engaged in illegal activity and/or
                  participated in any Prohibited Activity.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="14">
            <h2>14. Deleting Your Own Account</h2>
            <ul>
              <li>
                <p>
                  14.1   If you want to delete your Account, please do so via
                  your “Profile” section. Any refund transaction in your Account
                  will be deleted immediately, and you will lose your right to
                  refunds. We advise you to clear all your predictions'
                  transactions by requesting refunds for faulty predictions
                  before deleting your Account.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="15">
            <h2>15. Software and Technology</h2>
            <ul>
              <li>
                <p>
                  15.1   You may only use the Software for the purpose of using
                  the Services as they’re designed to be used and, except to the
                  extent permitted by applicable law, for no other purposes.
                </p>
              </li>
              <li>
                <p>
                  15.2   We grant to you a personal, non-exclusive,
                  non-transferable right to use the Software, for the sole
                  purpose of using the Services as they’re designed to be used,
                  in accordance with the following provisions:
                </p>
                <ul>
                  <li>
                    <p>
                      (a)   You may not: (i) install or load the Software onto a
                      server or other networked device or take other steps to
                      make the Software available via any form of "bulletin
                      board", online service or remote dial-in or network to any
                      other person; (ii) sublicense, assign, rent, lease, loan,
                      transfer or copy (except as expressly provided elsewhere
                      in these Terms) your license to use the Software or make
                      or distribute copies of the Software; (iii) enter, access
                      or attempt to enter or access or otherwise bypass our
                      security system or interfere in any way (including, but
                      not limited to, robots or similar devices) with the
                      relevant products or the Services or attempt to make any
                      changes to the Software and/or any features or components
                      thereof; or (iv) copy or translate any user documentation
                      provided online or in electronic format. In addition, and
                      except to the minimum extent permitted by applicable law
                      in relation to computer programs, you are not permitted
                      to: (a) translate, reverse engineer, decompile,
                      disassemble, modify, create derivative works based on, or
                      otherwise modify the Software; or (b) reverse engineer,
                      decompile, disassemble, modify, adapt, translate, make any
                      attempt to discover the source code of the Software or to
                      create derivative works based on the whole or on any part
                      of the Software.
                    </p>
                  </li>

                  <li>
                    <p>
                      (b)   You do not own the Software. The Software is owned
                      and is the exclusive property of Winperks. The Software
                      and accompanying documentation are our proprietary
                      products and are protected throughout the world by
                      copyright law. Your use of the Software does not give you
                      ownership of any intellectual property rights in the
                      Software.
                    </p>
                  </li>

                  <li>
                    <p>
                      (c)   The Software is provided "as is" and we do not
                      guarantee that: (i) the Software will meet your
                      requirements; (ii) the Software will not infringe any
                      third party’s intellectual property rights; (iii) the
                      operation of the Software will be error-free or
                      uninterrupted; (iv) any defects in the Software will be
                      corrected.
                    </p>
                  </li>

                  <li>
                    <p>
                      (d)   In the event of communications or system errors
                      occurring in connection with the settlement of Accounts or
                      other features or components of the Software, we will not
                      have any liability to you or to any third party in respect
                      of such errors. In the event of such errors, we are
                      entitled to remove all relevant products from the Services
                      and take any other action to correct such errors.
                    </p>
                  </li>

                  <li>
                    <p>
                      (e)   You acknowledge that how you use the Software is
                      outside of our control and at your own risk.
                    </p>
                  </li>

                  <li>
                    <p>
                      (f)   The Software may include secret and valuable
                      information. You are not entitled to use or disclose that
                      information other than strictly in accordance with these
                      Terms.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  15.3   You must not access without authority, interfere with,
                  damage, or disrupt the Services or any part of them; any
                  equipment or network on which the Services are stored; any
                  software used in connection with the provision of the
                  Services; or any equipment, software, or website owned or used
                  by a third party. We will not be liable for any loss caused by
                  cyberattacks (such as denial-of-service attack), viruses, or
                  other technologically harmful material that may damage your
                  computer equipment, programs, data, or other proprietary
                  material due to your use of the Services, the software, or
                  your downloading of any material posted on it or on any linked
                  website.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="16">
            <h2>16. Third Party Content</h2>
            <ul>
              <li>
                <p>
                  16.1   Where the Services contain links to third-party
                  websites and resources, these links are provided for your
                  information only. We have no control over the content of these
                  sites or resources, and accept no liability for them or for
                  any loss or damage that may arise from your use of them. The
                  inclusion of a link to a third party website does not
                  constitute an endorsement of that third-party’s website,
                  product or services (if applicable).
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="17">
            <h2>17. Malfunctions, Errors and Interruptions</h2>
            <ul>
              <li>
                <p>
                  17.1   We try to provide you with a high-quality platform, but
                  we cannot guarantee that the Services will at all times
                  perform correctly. Various kinds of mistakes and errors can
                  occur — technical, human, and otherwise — and you understand
                  and agree that we can only provide the Services to you as we
                  make them available from time to time.
                </p>
              </li>
              <li>
                <p>
                  17.2   In this Section we set out a description of the things
                  that can go wrong and the rights that we have in such events.
                  In Section 18 below we set out how we will not be liable to
                  you when these (and other) things go wrong.
                </p>
              </li>
              <li>
                <p>
                  17.3   Below are some key definitions to help you understand
                  this Section:
                </p>
                <ul>
                  <li>
                    <p>
                      A <strong>“Malfunction”</strong> refers to where an
                      automated process fails to operate as designed or
                      intended. This can occur, for example, where a piece of
                      computer software produces a deviation from its intended
                      performance specifications or parameters, or where two
                      pieces of software fail to interface or communicate with
                      each other in the manner intended or designed, in either
                      case resulting in an unintended outcome. These
                      malfunctions may or may not be apparent to us or to you
                      and they may only be discovered upon retrospective
                      investigation and analysis of settlement instructions,
                      game outcomes, or winnings.{" "}
                    </p>
                  </li>
                  <li>
                    <subsection>
                      <p>
                        An <strong>“Error”</strong> refers to a mistake or error
                        that is not exclusively technical, for example the
                        mistaken posting of incorrect odds, or the offer of
                        inaccurate predictions. Errors can arise from human
                        mistakes, administrative, operational or systems
                        failures and combinations of all of these. Errors
                        include (but aren’t necessarily limited to) so-called
                        ‘obvious’ errors, for example where:{" "}
                      </p>
                    </subsection>
                    <ul>
                      <li>
                        <p>
                          (a)   Predictions are accepted for events that should
                          have been suspended or concluded, for example, when an
                          event is already underway or has ended (except where
                          real-time predictions are explicitly permitted and
                          accepted).
                        </p>
                      </li>
                      <li>
                        <p>
                          (b)   A prediction result is finalized, but it is
                          later discovered that the outcome was based on
                          incorrect information or data, such as the number of
                          goals scored or points earned.{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          (c)   Event odds offered via the Services are
                          substantially out of the market (i.e., significantly
                          different from those available in the general market
                          (including from wagering operators) at the time of a
                          purchase Transaction);
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      An <strong>“Interruption”</strong> refers to an
                      interruption in a purchase Transaction for any reason, for
                      example, an interruption caused by the failure of the
                      internet or connectivity, or severe latency in
                      communications, or technical failure.{" "}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  17.4   Where any of the above occur, we will handle them as
                  described below and it is important that you understand and
                  agree to the following before you use the Services, as
                  mentioned in the “Important Information” section at the top of
                  these General Terms and Conditions:{" "}
                </p>
                <ul>
                  <li>
                    <subsection>
                      <h3>Malfunctions</h3>
                      <ul>
                        <li>
                          <p>
                            (a)   If, as a result of a Malfunction, your Account
                            is credited with refunds that you would not have
                            received were it not for that Malfunction, we will
                            have the right to void the relevant and any related
                            Transaction(s) and withhold or claim the relevant
                            amount. This applies whether the Malfunction was
                            apparent to you or to us or not.
                          </p>
                        </li>
                        <li>
                          <p>
                            (b)   Where any Malfunction is or becomes apparent
                            or obvious to you or you suspect that a Malfunction
                            may be occurring, you agree that you will cease your
                            use of the relevant Service(s) immediately, and will{" "}
                            <a
                              class="anchor"
                              href="mailto:contact@winperks.info"
                            >
                              Contact Us
                            </a>{" "}
                            immediately.
                          </p>
                        </li>
                      </ul>
                    </subsection>
                  </li>
                  <li>
                    <subsection>
                      <h3>Errors</h3>
                      <ul>
                        <li>
                          <p>
                            (a)   In the event of an Error, we will have the
                            right to suspend the affected prediction while we
                            investigate the issue, and we will void
                            Transactions.
                          </p>
                        </li>
                        <li>
                          <p>
                            (b)   If as a result of an Error, you will receive
                            refunds that you would not have received were it not
                            for that Error.
                          </p>
                        </li>
                        <li>
                          <p>
                            (c)   Incorrect Refund Amount – Transactions that
                            have been refunded where the amount is incorrect
                            (due to an Error in the underlying data or
                            prediction outcome) will be recalculated and
                            adjusted to the correct amount.
                          </p>
                        </li>
                        <li>
                          <p>
                            (d)   Where any Error is or becomes apparent or
                            obvious to you or you suspect that an Error may be
                            occurring, you agree that you will cease your use of
                            the relevant Service(s) immediately, and will{" "}
                            <a
                              class="anchor"
                              href="mailto:contact@winperks.info"
                            >
                              Contact Us
                            </a>{" "}
                            immediately.
                          </p>
                        </li>
                      </ul>
                    </subsection>
                  </li>
                  <li>
                    {" "}
                    <subsection>
                      <h3>Interruptions </h3>
                      <ul>
                        <li>
                          <p>
                            (a)  Our priority is to ensure that we deal with
                            Interruptions in a way that is fair and does not
                            systematically disadvantage our customers.
                          </p>
                        </li>
                        <li>
                          <p>
                            (b)  We reserve the right to suspend predictions,
                            void Transactions and refund customers.
                          </p>
                        </li>
                      </ul>
                    </subsection>
                  </li>
                </ul>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="18">
            <h2>18. Limitation of Liability</h2>
            <ul>
              <li>
                <p>
                  18.1   In no event shall Winperks’s total liability to you for
                  all claims arising from or relating to the terms or your
                  access to or use of the service exceed the amount paid by you
                  for any product, information, or service purchased by you from
                  Winperks on the Services. You acknowledge and agree that if
                  you have not paid us any amounts for any products, information
                  or service prior to the date on which you first assert any
                  claim, your sole and exclusive remedy for any dispute with us,
                  is to stop using the Services and cancel your account.
                </p>
              </li>
              <li>
                <p>
                  18.2   You acknowledge and agree that we shall not be liable
                  for any loss of profits, loss of business opportunities,
                  indirect, incidental, special, exemplary, punitive, or
                  consequential damages, including, but not limited to, damages
                  arising from any type or manner of commercial, business or
                  financial loss, even if Winperks had actual or constructive
                  knowledge of the possibility of such damages and regardless of
                  whether such damages were foreseeable.
                </p>
              </li>
              <li>
                <p>
                  18.3   As mentioned in the “Important Information” section at
                  the top of these general terms and conditions, notwithstanding
                  our regulated status, we will not be liable to you under these
                  terms, nor do we owe you a duty of care, in respect of our
                  compliance or otherwise with any regulatory requirements that
                  may apply to us from time to time.
                </p>
              </li>
              <li>
                <p>
                  18.4   Certain jurisdictions do not allow limitations on
                  implied warranties or the exclusion or limitation of certain
                  damages. If these laws apply to you, some disclaimers,
                  exclusions or limitations may not apply to you, and you may
                  have additional rights. In such jurisdictions, the
                  disclaimers, exclusions and limitations are limited to the
                  fullest extent permitted by law.
                </p>
              </li>
              <li>
                <p>
                  18.5   From time to time, all or part of the services may be
                  unavailable because of our maintenance of the services and/or
                  alteration or amendment of any of the services and we will not
                  be liable to you in these circumstances.
                </p>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="19">
            <h2>19. Responsible Gaming</h2>
            <ul>
              <li>
                <h3>No Promotion of Betting</h3>
                <p>
                  19.1   We want to emphasize that we do not promote or
                  encourage betting in any form. Winperks does not advertise or
                  endorse the act of placing bets. We fully respect the laws and
                  regulations regarding betting in all regions. You are solely
                  responsible for betting on other platforms based on our
                  predictions and for any loss that may arise therein.
                </p>
              </li>
              <li>
                <>
                  {" "}
                  <h3>Trusted Guidance</h3>
                  <p>
                    19.2   While we do not promote betting, we believe that
                    providing reliable and informative predictions can help make
                    the process more secure for those who opted to do it. We aim
                    to become a trusted guide, helping bettors make more
                    informed decisions. Betting should be considered as a form
                    of entertainment, not a reliable source of income or
                    investment. Wagering involves risks, and there is no
                    guarantee of a return on investment. We encourage all users
                    to understand the inherent risks involved. We advocate for
                    the use of our service strictly for recreational purposes.
                    Our platform is designed to add an element of fun to your
                    sports viewing experience. If you or someone you know has a
                    gambling problem, please call 1-800-Gambler.
                  </p>
                  <p>
                    19.3  Although we will use our reasonable efforts to enforce
                    our responsible gaming policies, we do not accept any
                    responsibility or liability if you nevertheless continue
                    playing and/or seek to use the Services with the intention
                    of deliberately avoiding the relevant measures in place
                    (whether put in place by you or by us) and/or we are unable
                    to enforce our measures/policies for reasons outside of our
                    reasonable control including if you have provided us with
                    details which are not true and accurate in order to
                    circumvent any responsible gaming policies or measures.{" "}
                  </p>
                </>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="20">
            <h2>20. Intellectual Property</h2>
            <ul>
              <li>
                <h3> Ownership of Intellectual Property</h3>
                <p>
                  20.1   All content, features, and functionality available on
                  or through our sports predictions platform, including but not
                  limited to software, algorithms, designs, graphics, text,
                  data, audio, video, and other materials (collectively, the
                  "Content"), are the exclusive property of Winperks, and are
                  protected by international copyright, trademark, patent, trade
                  secret and other intellectual property or proprietary right
                  laws.
                </p>
              </li>
              <li>
                <>
                  <h3>License to Use</h3>
                  <p>
                    20.2   Subject to your compliance with these Terms and
                    Conditions, Winperks grants you a limited, non-exclusive,
                    non-transferable, non-sublicensable license to access and
                    use the app for your personal, non-commercial use. This
                    license does not allow you to:
                    <ul>
                      <li>
                        <p>
                          Reproduce, distribute, upload, post, transmit,
                          publicly display any part of the Content.
                        </p>
                      </li>
                      <li>
                        <p>
                          Modify, create derivative works from, copy, republish
                          any part of the Content.
                        </p>
                      </li>

                      <li>
                        <p>
                          Reverse engineer, decompile, disassemble, or otherwise
                          attempt to discover the source code of any software
                          included in the platform.
                        </p>
                      </li>

                      <li>
                        <p>
                          Use any data mining, robots, or similar data gathering
                          or extraction methods.
                        </p>
                      </li>
                    </ul>
                    Any use of the Content not specifically authorized in these
                    Terms and Conditions is strictly prohibited and will
                    terminate the license granted herein.
                  </p>
                </>
              </li>
              <li>
                <>
                  <h3>Trademarks</h3>
                  <p>
                    20.3   All trademarks, service marks, logos, trade names,
                    and trade dress (collectively, the "Trademarks") displayed
                    on our web application are the registered and/or
                    unregistered Trademarks of Winperks or their respective
                    owners. Nothing contained in the Services or these Terms and
                    Conditions grants any license or right to use any Trademark
                    displayed on the Service without our prior written
                    permission.
                  </p>
                </>
              </li>
              <li>
                <>
                  <h3>User Contributions</h3>
                  <p>
                    20.4    We value your feedback and suggestions, and by
                    submitting or posting any materials or content on the
                    Service (collectively, "User Contributions"), you grant
                    Winperks a worldwide, non-exclusive, royalty-free,
                    perpetual, irrevocable, and fully sublicensable right to
                    use, reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, and display such User
                    Contributions in any form, media, or technology, whether now
                    known or hereafter developed, without compensation to you.
                  </p>
                </>
              </li>

              <li>
                <>
                  <h3>Infringement Claims</h3>
                  <p>
                    20.5   If you believe that any content available on our
                    platform infringes your intellectual property rights, please
                    notify us immediately by providing a detailed description of
                    the alleged infringement and sufficient information for us
                    to verify your claim. We will take appropriate actions in
                    accordance with applicable intellectual property laws.
                  </p>
                </>
              </li>
            </ul>
          </setion>
        </li>
        <li>
          <setion id="21">
            <h2>21. Privacy Policy</h2>
            <ul>
              <li>
                <p>
                  21.1   Your personal information is processed in accordance
                  with our Privacy Policy, a copy of which is available by
                  clicking{" "}
                  <a class="anchor" target="_blank" href="/privacy">
                    here
                  </a>
                  .
                </p>
              </li>
            </ul>
          </setion>
        </li>{" "}
        <li>
          <setion id="22">
            <h2>22. Other Important Legal Terms</h2>
            <ul>
              <li>
                <p>
                  22.1   If you breach these Terms and we take no action against
                  you, we will still be entitled to use our rights and remedies
                  in any other situation where you breach these Terms.
                </p>
              </li>
              <li>
                <p>
                  22.2   We reserve all rights not granted to you in these Terms
                  and Conditions. The rights and remedies provided are
                  cumulative and do not exclude other rights or remedies
                  available to us, unless otherwise stated in these Terms.
                </p>
              </li>
              <li>
                <p>
                  22.3   If any provision of these Terms is found by any court
                  or body of a certain jurisdiction to be invalid or
                  unenforceable, the other provisions will continue to apply.
                </p>
              </li>
              <li>
                <p>
                  22.4   We may transfer our rights and/or obligations under
                  these Terms to another organization at any time. Additionally,
                  we may transfer our rights or obligations to another company
                  or any other legal entity, including if we restructure or
                  there is a sale of our business. You agree that we may do so
                  provided that, in the case of such a transfer, after we notify
                  you of the date on which such transfer takes place, your
                  rights under these Terms will be against the new legal entity.
                </p>
              </li>
              <li>
                <p>
                  22.5   These Terms are personal to you. You may not transfer
                  your rights or obligations under these Terms, or your account,
                  to anyone else.
                </p>
              </li>
              <li>
                <p>
                  22.6   If we need to notify or contact you under these Terms,
                  we will do so using the details registered to your Account or
                  via notification on our website and/or other parts of the
                  Services. If you want to contact us, please do so via email{" "}
                  <a class="anchor" href="mailto:contact@winperks.info">
                    Contact Us
                  </a>
                  .
                </p>
              </li>
            </ul>
          </setion>
        </li>
      </ul>
    </main>
  );
};

export default Terms;
