import React from "react";
import SportsNavbar from "../components/SportsNavbar/SportsNavbar";
import OddsSection from "../components/OddsSection/OddsSection";

import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { Navigate } from "react-router-dom";
import "./Home.css";
import Judge from "../assets/images/players/Aaron_Judge.png";
import Neagu from "../assets/images/players/Christina_Neagu.png";
import McDavid from "../assets/images/players/Connor_McDavid.png";
import Taurasi from "../assets/images/players/Diana_Taurasi.png";
import GuimaraesEgonu from "../assets/images/players/GuimaraesEgonu.png";
import James from "../assets/images/players/Lebron_James.png";
import Messi from "../assets/images/players/Lionel_Messi.png";
import Poulin from "../assets/images/players/Marie_Poulin.png";
import Hansen from "../assets/images/players/Mikkel_Hansen.png";
import Djokovic from "../assets/images/players/Novak_Djokovic.png";
import Mahomes from "../assets/images/players/Patrick_Mahomes.png";
import Kerr from "../assets/images/players/Sam_Kerr.png";
import Ohtani from "../assets/images/players/Shohei_Ohtani.png";
import Bradey from "../assets/images/players/Tom_Bradey.png";
import Leon from "../assets/images/players/Wilfredo_Leon.png";
import { useState } from "react";
import { useAppContext } from "../context/appContext";
import { DateRangeSelector } from "../components";

function Home() {
  const { user } = useAppContext();
  const [selectedSport, setSelectedSport] = useState("Soccer");
  const [selectedRange, setSelectedRange] = useState(1);
  const handleRangeSelect = (range) => {
    setSelectedRange(range);
  };
  const isInvisible = selectedSport === "Tennis";
  const sportImages = {
    Soccer: {
      images: [Messi, Kerr],
      altTags: ["Lionel Messi by AI", "Sam Kerr by AI"],
    },
    USFootball: {
      images: [Mahomes, Bradey],
      altTags: ["Patrick Mahomes by AI", "Tom Bradey by AI"],
    },
    Basketball: {
      images: [James, Taurasi],
      altTags: ["Lebron_James by AI", "Diana Taurasi by AI"],
    },
    Handball: {
      images: [Neagu, Hansen],
      altTags: ["Christina Neagu by AI", "Mikkel Hansen by AI"],
    },
    Volleyball: {
      images: [Leon, GuimaraesEgonu],
      altTags: ["Wilfredo Leon by AI", "Guimaraes & Egonu by AI"],
    },
    Hockey: {
      images: [McDavid, Poulin],
      altTags: ["Connor McDavid by AI", "Marie-Philip Poulin by AI"],
    },
    Tennis: {
      images: [Djokovic, Djokovic],
      altTags: ["Novak Djokovic by AI", "Novak Djokovic by AI"],
    },
    Baseball: {
      images: [Ohtani, Judge],
      altTags: ["Shohei Ohtani by AI", "Aaron Judge by AI"],
    },
  };
  return (
    <React.Fragment>
      {user && <Navigate to="/prediction-shop" />}
      <div className="home-container">
        <Navbar />
        <div id="sports">
          <SportsNavbar
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
          />
        </div>
        <div className="date-range-selector">
          <DateRangeSelector
            Sport={selectedSport}
            selectedRange={selectedRange}
            onRangeSelect={handleRangeSelect}
          />
        </div>
        <div className="home-content">
          <div className={isInvisible ? "odds-c-i" : "odds-container"}>
            <img
              src={sportImages[selectedSport].images[0]}
              alt={sportImages[selectedSport].altTags[0]}
              className="left-image"
            />
            <OddsSection
              selectedSport={selectedSport}
              range={selectedRange}
              image={true}
              className="odds-component"
            />

            <img
              src={sportImages[selectedSport].images[1]}
              alt={sportImages[selectedSport].altTags[1]}
              className={isInvisible ? "invisible-right-image" : "right-image"}
            />
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Home;
