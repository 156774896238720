import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import "./SharedLayout.css";
import { DNavbar, BigSidebar, SmallSidebar } from "../../components";
function SharedLayout() {
  return (
    <section className="sharedLayout">
      <main className="dashboard">
        <SmallSidebar />
        <BigSidebar />
        <div className="dashboard-grid-item">
          <DNavbar />
          <div className="dashboard-page">
            <Outlet />
          </div>
        </div>
        <div className="dashboard-footer">
          <Footer />
        </div>
      </main>
    </section>
  );
}

export default SharedLayout;
