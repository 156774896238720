import { useAppContext } from "../../context/appContext";
import Loading from "../Loading/Loading";
import Prediction from "../Prediction/Prediction";
import Alert from "../Alert/Alert";
import "./PredictionsContainer.css";
import PageBtnContainer from "../PageBtnContainer/PageBtnContainer";
import { useEffect } from "react";
const PredictionsContainer = () => {
  const {
    getPredictions,
    predictions,
    isLoading,
    page,
    totalPredictions,
    search,
    searchStatus,
    sort,
    numOfPages,
    showAlert,
  } = useAppContext();
  useEffect(() => {
    getPredictions();
  }, [page, search, searchStatus, sort]);
  if (isLoading) {
    return <Loading center />;
  }

  if (predictions.length === 0) {
    return (
      <section className="predictions-container">
        <h2>No predictions to display...</h2>
      </section>
    );
  }

  return (
    <section className="predictions-container">
      {showAlert && <Alert />}
      <h5 className="tp">
        {totalPredictions} prediction{totalPredictions > 1 && "s"} found
      </h5>
      <div className="predictions">
        {predictions.map((prediction) => {
          return <Prediction key={prediction._id} {...prediction} />;
        })}
      </div>
      {numOfPages > 1 && <PageBtnContainer />}
    </section>
  );
};

export default PredictionsContainer;
