import "./PasswordReset.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Error } from "../../pages";

const PasswordReset = () => {
  const [validUrl, setValidUrl] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [focusedPwd, setFocusedPwd] = useState(false);
  const [focusedCnf, setFocusedCnf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const param = useParams();
  const url = `/api/v1/reset-password/${param.id}/${param.token}`;

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        await axios.get(url);
        setValidUrl(true);
      } catch (error) {
        setValidUrl(false);
      }
    };
    verifyUrl();
  }, [param, url]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await axios.post(url, { password });
      setMsg(data.message);
      setError("");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      // window.location = "/login";
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
        setMsg("");
      }
    } finally {
      setIsLoading(false);
    }
  };
  if (validUrl === null) {
    return null;
  }
  return (
    <>
      {validUrl ? (
        <div className="pw-reset">
          <form onSubmit={handleSubmit}>
            <h1>Reset Password</h1>
            <input
              type="password"
              placeholder="New Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              onFocus={() => setFocusedPwd(true)}
              onBlur={() => setFocusedPwd(true)}
              focused={focusedPwd.toString()}
              pattern="^(?=.*\p{L})(?=.*\p{N}).{8,20}$"
              required
            />
            <span>
              Password should be 8 to 20 characters and include at least 1
              letter and 1 number
            </span>
            <input
              type="password"
              placeholder="Confirm New Password"
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              onFocus={() => setFocusedCnf(true)}
              onBlur={() => setFocusedCnf(true)}
              focused={focusedCnf.toString()}
              pattern={password}
              required
            />
            <span>Passwords don't match</span>
            {error && <div className="error_msg">{error}</div>}
            {msg && <div className="success_msg">{msg}</div>}
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Resetting..." : "Submit"}
            </button>
          </form>
        </div>
      ) : (
        <Error />
      )}
    </>
  );
};

export default PasswordReset;
