import "./PredictionInfo";
const PredictionInfo = ({ icon, text }) => {
  return (
    <div className="prediction-info">
      <span className="icon">{icon}</span>
      <span className="text">{text}</span>
    </div>
  );
};

export default PredictionInfo;
