import "./Footer.css";
import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const displayYear = currentYear > 2024 ? ` - ${currentYear}` : "";
  return (
    <section className="footer">
      <div className="footer-top">
        <nav className="footer-winperks">
          <h1>Winperks</h1>
          <ul>
            <li>
              <Link
                to="/home"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("navbar");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("about");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/about#mission"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("mission");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Mission
              </Link>
            </li>
          </ul>
        </nav>
        <nav className="footer-predictions">
          <h1>Predictions</h1>
          <ul>
            <li>
              <Link
                to="/predictions#solutions"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("solutions");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Solutions
              </Link>
            </li>
            <li>
              <Link
                to="/predictions#pricing"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("pricing");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/predictions#refund"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("refund");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Refund
              </Link>
            </li>
          </ul>
        </nav>

        <nav className="support">
          <h1>Support</h1>
          <ul>
            <li>
              <Link
                to="/help#help"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("help");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                Help
              </Link>
            </li>

            <li>
              <Link
                to="/help#faq"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("faq");
                    if (element) {
                      window.requestAnimationFrame(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                      });
                    }
                  }, 300);
                }}
              >
                FAQ
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-bottom">
        <div className="social">
          <div>
            <h1>Follow us</h1>
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={32} color="#9E0FEC" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={32} color="#9E0FEC" />
              </a>
              <a
                href="https://www.x.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter size={32} color="#9E0FEC" />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size={32} color="#9E0FEC" />
              </a>
            </div>
          </div>
          <div>
            <h1>Contact us</h1>
            <div className="social-icons">
              <a href="mailto:support@winperks.info">
                <MdEmail size={32} color="#9E0FEC" />
              </a>
              <a href="mailto:contact@winperks.info">
                <HiOutlineMail size={32} color="#9E0FEC" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-other-pages">
          <div className="copyright">
            <h1>© Winperks 2024{displayYear}</h1>
          </div>
          <ul>
            <li>
              <Link to="/terms" target="_blank">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
