import React from "react";
import "./Privacy.css";
const Privacy = () => {
  return (
    <main className="terms">
      <h1>Privacy Policy</h1>
      <section>
        <h2>Who we are</h2>
        <p>
          Winperks (otherwise referred to herein as "we" or "us") adheres to the
          General Data Protection Regulation (GDPR) where applicable, and other
          international laws. This Privacy Policy is on behalf of the URL
          www.winperks.info. If you have any questions about this privacy policy
          please contact us. We understand that the privacy and the security of
          your personal information is important. This policy sets out what we
          do with your information and what we will do to keep it secure. It
          also explains where and how we collect your personal information, as
          well as your rights over any personal information we hold about you.
        </p>
      </section>
      <section id="p00">
        <h2>Contents</h2>
        <nav>
          <ul>
            <li>
              <a href="#p01">1. How we use your personal data</a>
            </li>
            <li>
              <a href="#p02">2. Your rights</a>
            </li>
            <li>
              <a href="#p03">3. The data we collect about you</a>
            </li>
            <li>
              <a href="#p04">
                4. The purpose for which we collect your personal data
              </a>
            </li>
            <li>
              <a href="#p05">5. How we secure your information </a>
            </li>
            <li>
              <a href="#p06">6. Data Transfers </a>
            </li>
            <li>
              <a href="#p07">7. Changes to information</a>
            </li>
          </ul>
        </nav>
      </section>
      <h2>Privacy Policy</h2>
      <ul>
        <li>
          <section id="p01">
            <h2>1. How we use your personal data</h2>
            <ul>
              <li>
                <p>
                  We will only use your personal data when the law allows us to.
                  Most commonly, we will use your personal data in the following
                  circumstances:
                </p>
              </li>
              <li>
                <p>
                  <ul>
                    <li>Where we need to perform our agreement with you.</li>
                    <li>
                      Where it is necessary for our legitimate interests (or
                      those of a third party) and your interests and fundamental
                      rights do not override those interests.{" "}
                    </li>
                    <li>
                      Where we need to comply with a legal or regulatory
                      obligation.{" "}
                    </li>
                  </ul>
                </p>
              </li>
            </ul>

            <p>
              We rely on your consent for processing your personal data for
              marketing purposes. You have the right to withdraw consent to
              marketing at any time by{" "}
              <a class="anchor" href="mailto:privacy@winperks.info">
                contacting us
              </a>{" "}
              or unsubscribing from our mailing list.
            </p>
          </section>
        </li>
        <li>
          <section id="p02">
            <h2>2. Your rights</h2>

            <ul>
              <li>
                <p>
                  Subject to certain limitations on certain rights you have the
                  following rights in relation to your information. You can
                  exercise any of these rights by{" "}
                  <a class="anchor" href="mailto:privacy@winperks.info">
                    contacting us
                  </a>{" "}
                  naming the right you are exercising along with the reason for
                  your request, if applicable.
                </p>
              </li>
              <li>
                <p>
                  <strong> Right of Access:</strong> you have the right to
                  request access to your personal data and supplementary
                  information.
                </p>
              </li>
              <li>
                <p>
                  <strong>Right to Rectification:</strong> you have the right to
                  request that inaccurate personal data be rectified or
                  completed if incomplete.
                </p>
              </li>
              <li>
                <p>
                  <strong>Right to Erasure:</strong> you have the right to
                  request erasure of your information at any time. We will
                  notify if there is a good reason for us to continue processing
                  it. Please ensure that you requested the refunds to your
                  predictions prior to automatically deleting your Account in
                  your “Profile” Section. The rules enacted under section 10 of
                  our Terms and Conditions related to the Refunds and Account
                  Deletion apply. You also have the right to ask us to delete or
                  remove your personal data or where we are required to erase
                  your personal data to comply with local law. If we are unable
                  to comply with this request you will be notified.
                </p>
              </li>
              <li>
                <p>
                  <strong>Right to Restrict Processing:</strong> this enables
                  you to ask us to suspend the processing of your personal data
                  in the following scenarios: (a) if you want us to establish
                  the data's accuracy; (b)you believe the processing is unlawful
                  and you oppose the erasure of your personal data and request
                  the restriction of its use instead;(c) where you need us to
                  hold the data even if we no longer require it as you need it
                  to establish, exercise or defend legal claims; or (d) you have
                  objected to our use of your data but we need to verify whether
                  we have overridden legitimate grounds to use it. Please note
                  that this right is not absolute.
                </p>
              </li>
              <li>
                <p>
                  <strong>Right to Data Portability:</strong> you have the right
                  to request the personal data you have provided to us. You may
                  make a data portability request via email.
                </p>
              </li>
              <li>
                <p>
                  <strong>Right to Object:</strong> you have the right to object
                  to our processing of your data. It should be noted that this
                  right is not absolute and may be subject to any reasons we
                  have to maintain that information such as our adherence to
                  legal and regulatory obligations. If you would like to object
                  to direct marketing, please unsubscribe from our mailing list
                  or{" "}
                  <a class="anchor" href="mailto:privacy@winperks.info">
                    contact us
                  </a>{" "}
                  requesting to no longer receive marketing materials.
                </p>
              </li>
              <li>
                <p>
                  You will not have to pay a fee to access your personal data
                  (or to exercise any other rights). However, we may charge a
                  reasonable fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we may refuse to
                  comply with your request in these circumstances.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    It should be noted that the ability to perform our services
                    relies on the processing of certain information. Therefore,
                    exercising certain rights may result in a loss of the
                    service or part of it.
                  </strong>
                </p>
              </li>
            </ul>
          </section>
        </li>
        <li>
          <section id="p03">
            <h2>3. The data we collect about you</h2>
            <p>
              Personal data (or personal information) means any information
              about an individual from which that person can be identified. It
              does not include data where the identity has been removed
              (anonymous data). We may collect, use, store and transfer
              different kinds of personal data about you which we have grouped
              together as follows:{" "}
            </p>
            <ul>
              <li>
                <p>
                  <strong>Identity Data</strong> includes first name, last name,
                  email address or similar identifier, date of birth and gender.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>Contact Data </strong>includes billing address,
                  location, email address and telephone numbers.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>Transaction Data </strong>includes reference details
                  about payments to and from you and other details of products
                  and services you have purchased from us.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>Technical Data </strong>your login data, browser type
                  and version, time zone setting and location, browser plug-in
                  types and versions, operating system and other technology on
                  the devices you use to access this website.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>Profile Data </strong>includes your email and
                  password, purchases or orders made by you, your interests,
                  preferences, correspondence feedback and survey responses.{" "}
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data </strong>includes information about how you
                  use our website, products and services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Marketing and Communications Data</strong> includes
                  your preferences in receiving marketing from us and your
                  communication preferences.{" "}
                </p>
              </li>
            </ul>
            <p>
              We may also collect, use and share Aggregated Data such as
              statistical or demographic data for any purpose. Aggregated Data
              may be derived from your personal data but is not considered
              personal data in law as this data does not directly or indirectly
              reveal your identity. For example, we may aggregate your Usage
              Data to calculate the percentage of users accessing a specific
              website feature. However, if we combine or connect Aggregated Data
              with your personal data so that it can directly or indirectly
              identify you, we treat the combined data as personal data which
              will be used in accordance with this privacy notice.
            </p>
            <h2>If you fail to provide personal data</h2>
            <p>
              Where we need to collect personal data by law, or under the terms
              of an agreement we have with you and you fail to provide that data
              when requested, we may not be able to perform the agreement we
              have or provide you with the service requested. Please note that
              any information which you transmit to us by email is not encrypted
              and is transmitted at your own risk.
            </p>
          </section>
        </li>
        <li>
          <section id="p04">
            <h2>4. The purpose for which we collect your personal data</h2>
            <p>
              We have set out below, in a table format, a description of the
              ways we plan to use your personal data and which of the legal
              bases we rely on to do so. We have also identified where our
              legitimate interests are applicable.{" "}
            </p>
            <p>
              Note that we may process your personal data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data. Please contact us if you have questions about the
              specific legal ground we are relying on to process your personal
              data where more than one ground has been set out in the table
              below.
            </p>
            <table>
              <tr>
                <th>Purpose/ Activity</th>
                <th>Type of Data</th>
                <th>Legal Basis</th>
              </tr>
              <tr>
                <td>Provision of products or services</td>
                <td>Identity</td>
                <td>Performance of a contract</td>
              </tr>
              <tr>
                <td>
                  To manage our agreement with you which will include: (a)
                  Notifying you about changes to our Terms and Conditions or
                  Privacy Policy.<br></br> <br></br> (b) Receiving marketing
                  materials.
                </td>
                <td>
                  Identity<br></br> Contact <br></br> Profile <br></br>{" "}
                  Marketing and Communications
                </td>

                <td>
                  Performance of a contract.<br></br> <br></br> Necessary to
                  comply with a legal obligation. Necessary for our legitimate
                  interests (to keep our records updated and for our newsletter
                  and marketing purposes).
                </td>
              </tr>
              <tr>
                <td>
                  Administer and improve our services (including data analysis,
                  support, online payment, refund, reporting and hosting of
                  data).{" "}
                </td>
                <td>
                  Identity <br></br> Contact <br></br> Technical<br></br>{" "}
                  Transaction<br></br> Profile<br></br> Usage
                </td>

                <td>
                  Necessary to comply with a legal and regulatory obligation.
                  <br></br>
                  <br></br>
                  Necessary for our legitimate interests (for running our
                  business, administration and IT services, and in the context
                  of a business reorganization.)
                </td>
              </tr>
              <tr>
                <td>
                  Ensure the security of our business and ensure our systems are
                  secure, efficient and up to date (including testing,
                  troubleshooting, network security and system maintenance.){" "}
                </td>
                <td>
                  Identity<br></br> Contact<br></br> Transaction <br></br>{" "}
                  Technical
                </td>

                <td>
                  Necessary to comply with a legal and regulatory obligation.
                  <br></br>
                  <br></br>
                  Necessary for our legitimate interests (to ensure that our
                  interaction with customers is appropriate, working and
                  efficient.)<br></br> <br></br> Necessary for our legitimate
                  interests (to prevent fraud, in protecting our business, staff
                  and customers.)
                </td>
              </tr>
            </table>
          </section>
        </li>
        <li>
          <section id="p05">
            <h2>5. How we secure your information</h2>
            <p>
              Winperks assures you that your personal data is:
              <ul>
                <li>processed in accordance with your rights; </li>
                <li>processed fairly and lawfully; </li>
                <li>obtained only for the above purposes; </li>
                <li>adequate, relevant and not excessive for its purpose; </li>
                <li>kept in a secure manner;</li>
                <li>not kept longer than is necessary for its purposes. </li>
              </ul>{" "}
            </p>
            <p>
              Winperks shall take all reasonable steps to ensure that your
              information is kept secure and protected. In this regard, we
              maintain appropriate technical and organizational measures to
              protect your data against unauthorized or unlawful processing.
            </p>
            <h2>What we may need from you</h2>
            <p>
              We may request specific information from you to help us confirm
              your identity and ensure your right to access your personal data
              (or to exercise any of your other rights). This is a security
              measure to ensure that personal data is not disclosed to any
              person who has no right to receive it. If you did not provide all
              your verification information upon registering with us, this
              information may be requested to complete your profile. We may also
              contact you to ask you for further information in relation to your
              request. Please remember to keep your password confidential.
            </p>
            <p>
              We try to respond to all legitimate requests within one month.
              Occasionally it may take us longer than a month if your request is
              particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>

            <p>
              It is your responsibility to maintain your personal information up
              to date and accurate. You have the right to lodge a complaint with
              a supervisory authority in your location. We would, however,
              appreciate the chance to deal with your concerns before you
              approach your local authority so please contact us in the first
              instance.
            </p>
          </section>
        </li>
        <li>
          <section id="p06">
            <h2>6. Data Transfers</h2>
            <p>
              We may need to share personal information with other organizations
              to ensure that we meet our legal obligations or where we need
              support in meeting your needs. Your data may be shared for regular
              operational purposes with entities such as cloud services, data
              centers, payment services and customer communication tools.
            </p>
            <p>
              We may transfer the personal information we collect about you to
              recipients in countries other than the country in which the
              information originally was collected. These countries may not have
              the same data protection laws as the country in which you
              initially provided the information. When we transfer your
              information to recipients in other countries, we will protect that
              information according to the applicable legal requirements.
            </p>
          </section>
        </li>
        <li>
          <section id="p07">
            <h2>7. Changes to information</h2>
            <p>
              Any changes to our Privacy Policy will be posted on this page. If
              we make any material or substantial changes to this Privacy
              Policy, we will use reasonable endeavors to notify you by email,
              notice on the Website or other agreed communications channels. We
              will communicate the revised Privacy Policy to you in advance so
              that you review and understand the changes before they become
              effective. If you decline the changes to the Privacy Policy, we
              will be unable to continue providing some or all of our products
              and services.
            </p>
          </section>
        </li>
      </ul>
    </main>
  );
};

export default Privacy;
