import axios from "axios";
import React, { useReducer, useContext } from "react";
import reducer from "./reducer";
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SIGNUP_USER_BEGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CREATE_PREDICTION_BEGIN,
  CREATE_PREDICTION_SUCCESS,
  CREATE_PREDICTION_ERROR,
  CHANGE_ODDS_FORMAT,
  GET_TOTAL_ODD,
  GET_PREDICTIONS_BEGIN,
  GET_PREDICTIONS_SUCCESS,
  SET_PREDICTION_SUCCSSFUL_BEGIN,
  SET_PREDICTION_SUCCESSFUL_SUCCESS,
  SET_PREDICTION_SUCCESSFUL_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  HANDLE_CHANGE,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  REFUND_PREDICTION_BEGIN,
  REFUND_PREDICTION_SUCCESS,
  REFUND_PREDICTION_ERROR,
  CHANGE_CURRENCY,
  DELETE_ACCOUNT_BEGIN,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  SET_REDIRECTED_TRUE,
  SET_REDIRECTED_FALSE,
} from "./actions";

// set as default
const token = localStorage.getItem("token");
const user = localStorage.getItem("user");
const userLocation = localStorage.getItem("location");
const initialState = {
  user: user ? JSON.parse(user) : null,
  userCreated: false,
  redirected: false,
  token: token,
  userLocation: userLocation || "",
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  showSidebar: false,
  oddsFormat: "decimal",
  currency: "USD",
  statusOptions: ["refunded", "successful", "pending"],
  totalOdd: 1,
  predictions: [],
  totalPredictions: 0,
  numOfPages: 1,
  page: 1,
  search: "",
  searchStatus: "all",
  sort: "latest",
  sortOptions: ["latest", "oldest", "a-z", "z-a"],
  stats: {},
  monthlyPredictions: [],
};

const AppContext = React.createContext();
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //axios
  const authFetch = axios.create({
    baseURL: "/api/v1",
    /*   headers: {
      Authorization: `Bearer ${state.token}`,
    }, */
  });
  // request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common["Authorization"] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };
  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };
  const addUserToLocalStorage = ({ user, token, location }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    localStorage.setItem("location", location);
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("location");
  };

  const signupUser = async (currentUser) => {
    dispatch({ type: SIGNUP_USER_BEGIN });
    try {
      const response = await axios.post("/api/v1/auth/signup", currentUser);
      const { msg } = response.data;

      dispatch({
        type: SIGNUP_USER_SUCCESS,

        payload: {
          msg,
        },
      });

      if (state.redirected) {
        setRedirectedFalse();
      }
    } catch (error) {
      //console.log(error.response);
      dispatch({
        type: SIGNUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
      clearAlert();
    }
  };
  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const { data } = await axios.post("/api/v1/auth/login", currentUser);
      const { user, token, location } = data;

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, token, location },
      });

      addUserToLocalStorage({ user, token, location });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch("/auth/updateUser", currentUser);
      //no token
      const { user, location } = data;
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, location, token },
      });
      addUserToLocalStorage({ user, location, token: initialState.token });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };
  const createPrediction = async (
    sport,
    totalOdd,
    oddsFormat,
    range,
    price,
    currency,
    orderId,
    captureId
  ) => {
    dispatch({ type: CREATE_PREDICTION_BEGIN });
    try {
      await authFetch.post("/predictions", {
        sport,
        totalOdd,
        oddsFormat,
        range,
        price,
        currency,
        orderId,
        captureId,
      });
      dispatch({ type: CREATE_PREDICTION_SUCCESS });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_PREDICTION_ERROR,
        payload: { msg: error.response.data.msg },
      });
      throw error;
    }
    clearAlert();
  };
  const changeOddsFormat = (format) => {
    dispatch({ type: CHANGE_ODDS_FORMAT, payload: format });
  };
  const changeCurrency = (currency) => {
    dispatch({ type: CHANGE_CURRENCY, payload: currency });
  };
  const getPredictions = async () => {
    const { page, search, searchStatus, sort } = state;
    let url = `/predictions?page=${page}&status=${searchStatus}&sort=${sort}`;
    if (search) {
      url = url + `&search=${search}`;
    }
    dispatch({ type: GET_PREDICTIONS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { predictions, totalPredictions, numOfPages } = data;
      dispatch({
        type: GET_PREDICTIONS_SUCCESS,
        payload: {
          predictions,
          totalPredictions,
          numOfPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };
  const getTotalOdd = async (Sport, range) => {
    let url = `/api/v1/data`;

    try {
      const { data } = await axios.post(
        url,
        { Sport, range },
        {
          "Cache-Control": "no-cache",
        }
      );
      const { totalOdd } = data;
      dispatch({
        type: GET_TOTAL_ODD,
        payload: {
          totalOdd,
        },
      });
    } catch (error) {
      //  console.log(error.response);
    }
    clearAlert();
  };
  const markSuccessful = async (id) => {
    dispatch({ type: SET_PREDICTION_SUCCSSFUL_BEGIN });
    try {
      await authFetch.patch(`/predictions/${id}`, {
        status: "successful",
      });
      dispatch({
        type: SET_PREDICTION_SUCCESSFUL_SUCCESS,
      });
      getPredictions();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: SET_PREDICTION_SUCCESSFUL_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const processRefund = async (id) => {
    dispatch({ type: REFUND_PREDICTION_BEGIN });
    try {
      const { data } = await authFetch(`/predictions/${id}`);
      const { captureId, price, currency } = data.prediction;

      const refund = await axios.post(`/api/orders/${captureId}/refund`, {
        price,
        currency,
      });

      await authFetch.patch(`/predictions/${id}`, {
        status: "refunded",
        refundId: refund.data.id,
      });
      dispatch({
        type: REFUND_PREDICTION_SUCCESS,
      });
      getPredictions();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: REFUND_PREDICTION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const showStats = async () => {
    dispatch({ type: SHOW_STATS_BEGIN });
    try {
      const { data } = await authFetch("/predictions/stats");
      dispatch({
        type: SHOW_STATS_SUCCESS,
        payload: {
          stats: data.defaultStats,
          monthlyPredictions: data.monthlyPredictions,
        },
      });
    } catch (error) {
      // console.log(error.response);
    }

    clearAlert();
  };
  const handleChange = ({ name, value }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };
  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  const changePage = (page) => {
    dispatch({ type: CHANGE_PAGE, payload: { page } });
  };
  const deleteAccount = async (userId) => {
    dispatch({ type: DELETE_ACCOUNT_BEGIN });
    try {
      await authFetch.delete(`/auth/deleteAccount/${userId}`);

      dispatch({
        type: DELETE_ACCOUNT_SUCCESS,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_ACCOUNT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }

    logoutUser();
  };
  const setRedirectedTrue = async () => {
    dispatch({
      type: SET_REDIRECTED_TRUE,
    });
  };
  const setRedirectedFalse = async () => {
    dispatch({
      type: SET_REDIRECTED_FALSE,
    });
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        signupUser,
        loginUser,
        toggleSidebar,
        logoutUser,
        updateUser,
        createPrediction,
        changeOddsFormat,
        getTotalOdd,
        getPredictions,
        markSuccessful,
        processRefund,
        showStats,
        handleChange,
        clearFilters,
        changePage,
        changeCurrency,
        deleteAccount,
        setRedirectedFalse,
        setRedirectedTrue,
        clearAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};
export { AppProvider, initialState, useAppContext };
