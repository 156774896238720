const FormRow = ({ type, name, value, handleChange, labelText, id }) => {
  return (
    <div className="form-row">
      <label htmlFor={id} className="form-label">
        {labelText || name}
      </label>
      <input
        id={id}
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className="form-inputs"
      />
    </div>
  );
};

export default FormRow;
