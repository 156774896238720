import axios from "axios";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";

const PaypalPayment = ({ orderData }) => {
  //   const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
  const { createPrediction } = useAppContext();
  const navigate = useNavigate();

  const createOrder = async (data) => {
    // Order is created on the server and the order id is returned
    // Define the default description
    let description = `Prediction for ${data.selectedSport} with Odd: ${data.odds} in ${data.range} days`;

    // Check if the date range is 60 days
    if (data.range === 60) {
      description = `Prediction for ${data.selectedSport} with Odd: ${data.odds} in Surprise Range`;
    }
    // console.log("from frontend", data);
    return axios
      .post(
        "/api/orders",

        // use the "body" param to optionally pass additional order information
        // like product skus and quantities
        {
          product: {
            description,
            cost: data.price,
            currency_code: data.currency,
            // currency_code: "USD",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      .then((response) => response.data.id);
    /*   .catch((error) => {
        throw error; // You might want to handle errors appropriately
      }); */
  };
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return axios
      .post(
        `/api/orders/${data.orderID}/capture`,
        {
          orderID: data.orderID,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("payment successful", response.data);
        // return response.data;

        // console.log(
        //   `Odds: ${orderData.odds}, Odds Format: ${orderData.oddsFormat}, Price: ${orderData.price} for ` +
        //     orderData.selectedSport
        // );
        const captureId =
          response.data?.purchase_units?.[0]?.payments?.captures?.[0]?.id;
        createPrediction(
          orderData.selectedSport,
          orderData.odds,
          orderData.oddsFormat,
          orderData.range,
          orderData.price,
          orderData.currency,
          data.orderID,
          captureId
        );
      })
      .then(() => {
        setTimeout(() => {
          navigate("/my-predictions");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <PayPalButtons
      options={{
        merchantId: "38FXD38ETP83L",
      }} 
      forceReRender={[orderData]}
      createOrder={() => createOrder(orderData)}
      onApprove={(data, actions) => onApprove(data, actions)}
    />
  );
};

export default PaypalPayment;
