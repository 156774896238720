import moment from "moment";
import {
  FaCalendarAlt,
  FaBriefcase,
  FaCheckCircle,
  FaBug,
  FaTimesCircle,
} from "react-icons/fa";
import { useAppContext } from "../../context/appContext";
import "./Prediction.css";
import PredictionInfo from "../PredictionInfo/PredictionInfo";
import { memo } from "react";
const Prediction = ({
  _id,
  sport,
  predictionSet,
  createdAt,
  status,
  totalOdd,
  oddsFormat,
  refundDeadline,
  price,
  currency,
}) => {
  const { markSuccessful, processRefund } = useAppContext();

  const renderOutcomeDescription = (prediction, sport) => {
    const { period, outcome, type } = prediction;
    switch (period) {
      case "num_0":
        switch (sport) {
          case "Soccer":
          case "Handball":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      Match to end in a draw {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the match: {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total goals over ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total goals under ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win the match with handicap {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          case "Volleyball":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      Match to end in a draw {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the match: {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total points over ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total points under ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win the match with handicap {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          default:
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      Game to end in a tie {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the game: {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total points over ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total points under ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win the game with handicap {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
        }
        break;

      case "num_1":
        switch (sport) {
          case "Basketball":
          case "USFootball":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      (1st quarter) to end in a tie {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the (1st quarter): {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total points over (1st quarter) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total points under (1st quarter) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win (1st quarter) with handicap{" "}
                    {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );
              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st quarter) over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st quarter) under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          case "Tennis":
          case "Volleyball":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      (1st set) to end in a tie {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the (1st set): {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total points over (1st set) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total points under (1st set) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win (1st set) with handicap {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st set) over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st set) under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          case "Baseball":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      (1st inning) to end in a tie {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the (1st inning): {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total points over (1st inning) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total points under (1st inning) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win (1st inning) with handicap{" "}
                    {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st inning) over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st inning) under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          case "Soccer":
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      (1st half) to end in a draw {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the (1st half): {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total goals over (1st half) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total goals under (1st half) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win (1st half) with handicap {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st half) over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st half) under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              default:
                return "Unknown outcome";
            }
            break;
          default:
            switch (type) {
              case "money_line":
                const team = outcome.home ? prediction.home : prediction.away;
                if (outcome.draw) {
                  return (
                    <div className="outcome">
                      (1st period) to end in a tie {"  "}
                      <span className="outcomeOdd">{outcome.draw}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="outcome">
                      To win the (1st period): {team}{" "}
                      <span className="outcomeOdd">
                        {outcome.home || outcome.away}
                      </span>
                    </div>
                  );
                }
              case "totals":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Total goals over (1st period) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Total goals under (1st period) ({outcome.points}){" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;
              case "spreads":
                const teamSpread = outcome.home
                  ? prediction.home
                  : prediction.away;
                const handicapSign = outcome.hdp >= 0 ? "+" : "";
                return (
                  <div className="outcome">
                    {teamSpread} to win (1st period) with handicap{" "}
                    {handicapSign}
                    {outcome.hdp}{" "}
                    <span className="outcomeOdd">
                      {outcome.home || outcome.away}
                    </span>
                  </div>
                );

              case "team_total":
                if (outcome.over) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st period) over: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.over}</span>
                    </div>
                  );
                } else if (outcome.under) {
                  return (
                    <div className="outcome">
                      Team{" "}
                      {outcome.team === "home"
                        ? prediction.home
                        : prediction.away}{" "}
                      total (1st period) under: {outcome.points}{" "}
                      <span className="outcomeOdd">{outcome.under}</span>
                    </div>
                  );
                }
                break;

              default:
                return "Unknown outcome";
            }
            break;
        }
        break;
      default:
        return "Unknown period";
    }
  };

  let date = moment(createdAt);
  date = date.format("MMM Do, YYYY");
  return (
    <article className="prediction">
      <header>
        <div className="main-icon">{sport.charAt(0)}</div>
        <div className="info">
          <h5>{sport}</h5>
        </div>
      </header>
      <div className="content">
        <div className="content-center">
          <div className={`status ${status}`}>
            {status === "pending" && <FaBriefcase />}
            {status === "successful" && <FaCheckCircle />}
            {status === "refunded" && <FaBug />}
            {status === "exceeded" && <FaTimesCircle />}
            {status}
          </div>
          <PredictionInfo icon={<FaCalendarAlt />} text={date} />
          {predictionSet.map((prediction) => {
            return (
              <div className="event" key={prediction._id}>
                <h4>{prediction.league_name}</h4>
                <h5 className="home-away">
                  <span className="home">{prediction.home}</span> -{" "}
                  <span className="away">{prediction.away}</span>
                </h5>
                <h5 className="outcome-desc">
                  {/*  <span className="prediction-type">{prediction.type}:</span>
                  {"  "} */}
                  {renderOutcomeDescription(prediction, sport)}{" "}
                </h5>
                {/* <h5>{new Date (prediction.starts).toLocaleString()}</h5> */}
                <h5>{moment.utc(prediction.starts).local().format("L LT")}</h5>
              </div>
            );
          })}
          <div className="total-odd">
            Total Odd: {oddsFormat === "american" && "+"}
            {totalOdd}
          </div>
          <div className={`price ${status}`}>
            Price:{price}
            {currency === "USD" ? "$" : "€"}
          </div>
        </div>
        {status === "pending" && (
          <footer className="status-footer">
            <div>
              Refund Deadline:{moment(refundDeadline).format("MMM Do, YYYY")}
            </div>
            <div className="actions">
              <button
                type="button"
                className="edit-btn"
                onClick={() => {
                  markSuccessful(_id);
                }}
              >
                Successful
              </button>
              <button
                type="button"
                className="delete-btn"
                onClick={() => processRefund(_id)}
              >
                Refund me
              </button>
            </div>
          </footer>
        )}
      </div>
    </article>
  );
};

export default memo(Prediction);
