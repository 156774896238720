import "./Unsubscribe.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import success from "../../assets/images/others/success-email.png";

const Unsubscribe = () => {
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const param = useParams();
  const unsubscribe = async (e) => {
    try {
      const url = `/api/v1/unsubscribe/${param.token}`;
      const { data } = await axios.get(url);
      setMsg(data.message);
      setError("");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
        setMsg("");
      }
    }
  };
  useEffect(() => {
    return () => unsubscribe();
  }, []);
  return (
    <div className="unsubscribe">
      {error && <div className="error_msg">{error}</div>}
      {msg && (
        <div className="success_unsubscribe">
          {msg}
          <img src={success} alt="success_img" />
        </div>
      )}
    </div>
  );
};

export default Unsubscribe;
