import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";

import {
  PriceContext,
  ButtonWithOddsSquare,
  ButtonWithOddsCircle,
  ButtonWithOddsTriangle,
  ButtonWithOddsRect,
  ButtonWithOddsStar,
  PriceDisplay,
} from "../OddsButtons/OddsButtons";
import "./OddsSection.css";
import PaypalPayment from "../PaypalPayment/PaypalPayment";
import WIN from "../../assets/images/others/WIN.svg";

function OddsSection({ selectedSport, range, image }) {
  const { user, isLoading, oddsFormat, changeOddsFormat, currency } =
    useAppContext();

  let exchangeRate = 0.924;
  const navigate = useNavigate();
  const [currentPrice, setCurrentPrice] = useState(0.0);
  const [clickedButtonPrice, setClickedButtonPrice] = useState(0.0);
  const [clicked, setClicked] = useState(null);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    // Reset clicked state when selectedSport or range changes
    setClicked(null);
    setCurrentPrice(0.0);
    setClickedButtonPrice(0.0);
    setOrderData({});
  }, [selectedSport, range, currency]);
  const handleClick = ({ odds, oddsFormat, price, currency, id }) => {
    if (user) {
      setCurrentPrice(price);
      setClickedButtonPrice(price);
      setClicked(id);
      setOrderData((prevOrderData) => {
        // Calculate the new order data based on the previous state
        return {
          ...prevOrderData,
          odds,
          oddsFormat,
          price,
          currency,
          selectedSport,
          range,
        };
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <section className="container-image-odds">
      <div className="odds__section">
        {!user && (
          <div className="odds-format__radio">
            Total Odds:
            <input
              type="radio"
              title="decimal odds format"
              name="oddsFormat"
              value="decimal"
              checked={oddsFormat === "decimal"}
              onChange={(e) => changeOddsFormat(e.target.value)}
            />
            Decimal
            <input
              type="radio"
              title="american odds format"
              name="oddsFormat"
              value="american"
              checked={oddsFormat === "american"}
              onChange={(e) => changeOddsFormat(e.target.value)}
            />
            American
          </div>
        )}

        <div className="odds-buttons__context">
          <PriceContext.Provider
            value={{
              currentPrice,
              setCurrentPrice,
              clickedButtonPrice,
              setClickedButtonPrice,
              setClicked,
              clicked,
            }}
          >
            <ul className="odds__list">
              <li>
                <ButtonWithOddsCircle
                  id={"b1"}
                  odds={2}
                  oddsFormat={oddsFormat}
                  price={
                    currency === "USD"
                      ? 5.0
                      : Math.round(5.0 * exchangeRate * 1e2) / 1e2
                  }
                  onClick={handleClick}
                  disabled={isLoading}
                />
              </li>
              <li>
                <ButtonWithOddsTriangle
                  id={"b2"}
                  odds={5}
                  oddsFormat={oddsFormat}
                  price={
                    currency === "USD"
                      ? 13.0
                      : Math.round(13.0 * exchangeRate * 1e2) / 1e2
                  }
                  onClick={handleClick}
                  disabled={isLoading}
                />
              </li>
              <li>
                <ButtonWithOddsSquare
                  id={"b3"}
                  odds={10}
                  oddsFormat={oddsFormat}
                  price={
                    currency === "USD"
                      ? 28.0
                      : Math.round(28.0 * exchangeRate * 1e2) / 1e2
                  }
                  onClick={handleClick}
                  disabled={isLoading}
                />
              </li>
              <li>
                <ButtonWithOddsRect
                  id={"b4"}
                  odds={20}
                  oddsFormat={oddsFormat}
                  price={
                    currency === "USD"
                      ? 60.0
                      : Math.round(60.0 * exchangeRate * 1e2) / 1e2
                  }
                  onClick={handleClick}
                  disabled={isLoading}
                />
              </li>
              <li>
                <ButtonWithOddsStar
                  id={"b5"}
                  odds={50}
                  oddsFormat={oddsFormat}
                  price={
                    currency === "USD"
                      ? 125.0
                      : Math.round(125.0 * exchangeRate * 1e2) / 1e2
                  }
                  onClick={handleClick}
                  disabled={isLoading}
                />
              </li>
            </ul>

            <div className="price-total">
              <PriceDisplay />
            </div>
          </PriceContext.Provider>

          {Object.keys(orderData).length !== 0 && (
            <PaypalPayment orderData={orderData} />
          )}
        </div>
      </div>
      {image ? (
        <img src={WIN} alt="Win or money back" className="c-image" />
      ) : null}
    </section>
  );
}

export default OddsSection;
