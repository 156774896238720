import "./DateRange.css";
import { useAppContext } from "../../context/appContext";
import { useCallback, memo, useEffect } from "react";
const DateRangeSelector = ({ Sport, selectedRange, onRangeSelect }) => {
  //const [selectedRange, setSelectedRange] = useState(1);
  const { getTotalOdd } = useAppContext();
  // const [selectedRange, setSelectedRange] = useState(1);
  const handleRangeSelect = useCallback(
    async (range) => {
      onRangeSelect(range);
      getTotalOdd(Sport, range);
    },
    [Sport, onRangeSelect]
  );
  useEffect(() => {
    handleRangeSelect(0); // Call the function during the initial render
  }, [Sport]);
  return (
    <div className="range-selector-wrapper">
      <h3 className="range__h3">Events latest</h3>
      <div className="range-buttons">
        <button
          type="button"
          className={`range-button ${
            selectedRange === 0 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(0)}
        >
          Today
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 1 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(1)}
        >
          Tomorrow
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 3 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(3)}
        >
          In 3 Days
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 7 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(7)}
        >
          Next Week
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 14 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(14)}
        >
          In 2 Weeks
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 30 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(30)}
        >
          Next Month
        </button>
        <button
          type="button"
          className={`range-button ${
            selectedRange === 60 ? "selected-range-button" : ""
          }`}
          onClick={() => handleRangeSelect(60)}
        >
          Surprise Me
        </button>
      </div>
    </div>
  );
};

export default memo(DateRangeSelector);
