import "./Help.css";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const Help = () => {
  return (
    <main className="help">
      <Navbar />
      <div className="help-content">
        <div id="help">
          <h2>How It Works:</h2>
          <ol>
            <li>
              <strong>Select Your Sport and Date Range:</strong>
              <br />
              Choose your preferred sport and specify the date range which you'd
              like for your predictions.
            </li>
            <li>
              <strong>Hover Over Total Odd to Check Availability:</strong>
              <br />
              Hover over the total odd to check its availability for the
              selected sport and date range.
            </li>
            <li>
              <strong>Prompted for Payment:</strong>
              <br />
              If it's clickable, it's available. You'll be prompted for payment.
            </li>
            <li>
              <strong>Successful Payment:</strong>
              <br />
              Upon successful payment, you will be redirected to find your
              prediction or navigate to 'my predictions' tab.
            </li>
            <li>
              <strong>Tracking Your Predictions:</strong>
              <br />
              Utilize the search bar to sort and keep track of your predictions.
              You can easily access and manage your predictions for better
              tracking and analysis.
            </li>
            <li>
              <strong>Refunds and Success Marking:</strong>
              <br />
              If a prediction turns out to be faulty, click 'refund me'. If a
              prediction is a winner, please mark it as successful.
            </li>
            <li>
              <strong>Consult Statistics:</strong>
              <br />
              Take advantage of our statistics section to make sure we are
              supporting you and becoming your guide for sports bet across
              various platforms.
            </li>
          </ol>
          <h2>Need Further Assistance?</h2>
          <p>
            If you have any questions or encounter any issues, please don't
            hesitate to contact our support team at (support@winperks.info).
            We're here to assist you every step of the way.
          </p>
        </div>
      </div>
      <br />
      <div className="faq">
        <div id="faq">
          <h2>Frequently Asked Questions (FAQ)</h2>
          <div>
            <h4>1. What sports do you provide predictions for?</h4>
            <p>
              We cover a wide range of sports, including but not limited to
              soccer, football, basketball, tennis, hockey, baseball, handball,
              and volleyball.
            </p>
          </div>

          <div>
            <h4>
              2. Do you provide live updates or only pre-match predictions?
            </h4>
            <p>
              We provide pre-match predictions with frequent updates for
              selected sports events. Our aim is to keep you informed about
              upcoming matches and provide insights for your betting decisions.
            </p>
          </div>

          <div>
            <h4>3. Can I access predictions for specific dates?</h4>
            <p>
              Yes, you can access predictions for specific dates by selecting
              the desired sport and specifying the date range within the app.
            </p>
          </div>
          <div>
            <h4>4. How frequently are predictions updated?</h4>
            <p>
              Predictions are updated daily to reflect the latest data in the
              sports world. We strive to provide timely updates to ensure that
              our customers have access to the most current information
              available.
            </p>
          </div>
          <div>
            <h4>5. How do you suggest predictions?</h4>
            <p>
              We prioritize accuracy and reliability in our prediction
              algorithms and processes and offer predictions to your best
              decisions.
            </p>
          </div>

          <div>
            <h4>6. How to subscribe to premium?</h4>
            <p>
              There is no premium subscription. Customers receive access to
              their tailored prediction based on a one time fee.
            </p>
          </div>
          <div>
            <h4>
              7. Can I get a refund if a prediction turns out to be incorrect?
            </h4>
            <p>
              Yes, we offer refunds upon request for predictions that are deemed
              faulty with a deadline of 14 days after the last game.
            </p>
          </div>
          <div>
            <h4>8. Is my payment information secure?</h4>
            <p>
              Yes, we prioritize the security of your payment information. Our
              app utilizes industry-standard encryption and security measures to
              ensure that your payment details are protected.
            </p>
          </div>

          <div>
            <h4>9. How do I contact you if I encounter any issues?</h4>
            <p>
              If you have any questions, concerns, or encounter any issues while
              using our platform, please don't hesitate to contact our support
              team. You can reach us via email at support@winperks.info.
            </p>
          </div>
          <div>
            <h4>
              10. How do I provide feedback or suggestions for improvement?
            </h4>
            <p>
              We value your feedback and suggestions for improving our app. You
              can submit feedback or contact our team at contact@winperks.info.
              Your input helps us enhance our app's functionality and your user
              experience.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Help;
